import type { FC } from 'react';
import { useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { t } from '@lingui/macro';
import { OrganizationRoles, type OrganizationRoles as OrganizationRolesType } from 'src/models/memberRoles';

type OrganizationRoleSelectProps = {
  value: OrganizationRolesType;
  onSelectionChanged: (value: OrganizationRolesType) => void;
  inputLabel: string;
};

const OrganizationRoleSelect: FC<OrganizationRoleSelectProps> = ({
  inputLabel,
  onSelectionChanged,
  value: valueProps
}) => {
  const [value, setValue] = useState<OrganizationRolesType>(valueProps);
  const ROLES = [
    { id: OrganizationRoles.Administrator, name: t`Administrator` },
    { id: OrganizationRoles.Member, name: t`Member` }
  ];

  return (
    <FormControl fullWidth={true}>
      <InputLabel id="role-label">{inputLabel}</InputLabel>
      <Select
        labelId="role-label"
        label={inputLabel}
        value={value}
        onChange={(e) => {
          setValue(e.target.value as OrganizationRolesType);
          onSelectionChanged(e.target.value as OrganizationRolesType);
        }}
      >
        {ROLES.map((role) => (
          <MenuItem key={role.id} value={role.id}>
            {role.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default OrganizationRoleSelect;
