import type { FC, ReactNode } from 'react';

// Components
import Item from './item';

import type { ListProps } from '@mui/material';
// Externals
import { List, Skeleton, ListItem } from '@mui/material';
import { matchPath } from 'react-router-dom';

interface ItemProps {
  path?: string;
  icon?: ReactNode;
  info?: ReactNode;
  children?: ItemProps[];
  title: string;
  loading?: boolean;
  onClick?: () => void;
}

interface SectionProps extends ListProps {
  items: ItemProps[];
  pathname: string;
  title: string;
}

const renderItem = ({ pathname, item, index }: { pathname: string; item: ItemProps; index: number }): JSX.Element => {
  if (item.loading) {
    return (
      <ListItem
        key={index}
        disableGutters
        sx={{
          display: 'flex',
          py: 0
        }}
      >
        <Skeleton sx={{ width: 54, height: 48 }} />
      </ListItem>
    );
  }

  const key = `${item.title}`;
  const exactMatch = item.path
    ? !!matchPath(
        {
          path: item.path,
          end: true
        },
        pathname
      )
    : false;

  return (
    <Item
      active={exactMatch}
      icon={item.icon}
      info={item.info}
      key={key}
      path={item?.path}
      title={item.title}
      onClick={item?.onClick}
    />
  );
};

const Section: FC<SectionProps> = (props) => {
  const { items, pathname, title, ...other } = props;

  return <List {...other}>{items.map((item, index) => renderItem({ pathname, item, index }))}</List>;
};

export default Section;
