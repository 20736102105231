import { Trans } from '@lingui/macro';
import { Box, Typography } from '@mui/material';
import { FC } from 'react';

interface NormalizationCellProps {
  sourceValue: string;
  normalizedValue: string;
}

const NormalizationCell: FC<NormalizationCellProps> = ({ sourceValue, normalizedValue }) => {
  return (
    <Box>
      <Typography>
        <Trans>Source</Trans> : {sourceValue}
      </Typography>
      <Typography>
        <Trans>Normalized</Trans> : {normalizedValue}
      </Typography>
    </Box>
  );
};

export default NormalizationCell;
