import { useCallback } from 'react';
import Navbar from './navbar';
import Sidebar from './sidebar';
import { Drawer, experimentalStyled } from '@mui/material';
import { Outlet, useSearchParams } from 'react-router-dom';
import { NAVBAR_HEIGHT } from 'src/constants/styles-constants';
import Settings from 'src/pages/member/sites/settings';

const LayoutRoot = experimentalStyled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  overflow: 'hidden',
  height: '100vh',
  width: '100%'
}));

const LayoutWrapper = experimentalStyled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  paddingTop: NAVBAR_HEIGHT
}));

const LayoutContainer = experimentalStyled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden'
});

const LayoutContent = experimentalStyled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto',
  position: 'relative',
  WebkitOverflowScrolling: 'touch'
});

const DashboardLayout = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const settingsTabSearchParams = searchParams.get('settings-tab');
  const isSettingsDrawerOpen = settingsTabSearchParams ? true : false;

  const closeSettingsDrawer = useCallback(() => {
    searchParams.delete('settings-tab');
    setSearchParams(searchParams, { replace: true });
  }, [searchParams, setSearchParams]);

  const openSettingsDrawer = useCallback(() => {
    searchParams.set('settings-tab', 'general');
    setSearchParams(searchParams, { replace: true });
  }, [searchParams, setSearchParams]);

  return (
    <LayoutRoot>
      <Navbar />
      <LayoutWrapper>
        <Sidebar setOpenSettings={openSettingsDrawer} />
        <LayoutContainer>
          <LayoutContent>
            <Outlet />
          </LayoutContent>
        </LayoutContainer>
      </LayoutWrapper>
      <Drawer open={isSettingsDrawerOpen} anchor="right" onClose={closeSettingsDrawer} transitionDuration={200}>
        <Settings onClose={closeSettingsDrawer} />
      </Drawer>
    </LayoutRoot>
  );
};

export default DashboardLayout;
