import type { FC } from 'react';
import { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField
} from '@mui/material';
import { t, Trans } from '@lingui/macro';
import OrganizationRoleSelect from 'src/components/selects/memberRoleSelect';
import { OrganizationMemberDto, useUpdateMemberMutation } from 'src/slices/organization';
import toast from 'react-hot-toast';
import useOrganizationId from 'src/hooks/useOrganizationId';
import { type OrganizationRoles } from 'src/models/memberRoles';

function handleEditMemberError(error) {
  if (error) {
    switch (error?.data?.type) {
      case 'entity-not-found':
        toast.error(
          t`The entity was not found in the database. Entity: ${error?.data?.entityName} with id: ${error?.data?.entityId}`
        );
        break;
      default:
        toast.error(error?.data?.detail || t`Something went wrong while updating the member.`);
        break;
    }
  } else {
    toast.error(t`Something went wrong.`);
  }
}

type EditMemberDialogProps = {
  member: OrganizationMemberDto;
  onClose: () => void;
  onCancel: () => void;
  onComplete: () => void;
};

const EditMemberDialog: FC<EditMemberDialogProps> = ({ member, onCancel, onClose, onComplete }) => {
  const [selectedRole, setSelectedRole] = useState<OrganizationRoles>(member.role);
  const organizationId = useOrganizationId();

  const [updateMember] = useUpdateMemberMutation();

  const handleSave = async () => {
    try {
      await updateMember({
        organizationId,
        memberId: member.id,
        role: selectedRole,
        siteIds: member.sites.map((site) => site.id)
      }).unwrap();
      toast.success(t`Member updated successfully.`);
    } catch (error) {
      handleEditMemberError(error);
    }
    onComplete();
  };

  const handleRoleChange = (role: OrganizationRoles) => {
    setSelectedRole(role);
  };

  return (
    <Dialog onClose={onClose} open={true}>
      <DialogTitle>
        <Trans>Update a member</Trans>
      </DialogTitle>
      <DialogContent>
        <Grid container={true} direction="column" spacing={2}>
          <Grid item={true}>
            <DialogContentText>
              <Trans>Please complete the information below.</Trans>
            </DialogContentText>
          </Grid>
          <Grid item={true}>
            <TextField
              fullWidth={true}
              label={t`Email`}
              margin="dense"
              size="small"
              value={member.email}
              disabled
              inputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item={true}>
            <OrganizationRoleSelect value={selectedRole} inputLabel={t`Role`} onSelectionChanged={handleRoleChange} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>
          <Trans>Cancel</Trans>
        </Button>
        <Button onClick={() => handleSave()}>
          <Trans>Save</Trans>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditMemberDialog;
