import { createSlice, PayloadAction } from '@reduxjs/toolkit';


export interface FlowState {
  color: string;
}

const initialState: FlowState = {
  color: '#555555'
};

const flowConfig = createSlice({
  name: 'flow-config',
  initialState,
  reducers: {
    initializeFlowConfig: (state, action: PayloadAction<FlowState>) => {
      state.color = action.payload.color;
    },
    setColor: (state, action: PayloadAction<string>) => {
      state.color = action.payload;
    }
  }
});

export const { initializeFlowConfig, setColor } = flowConfig.actions;
export const { reducer } = flowConfig;
