import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { ColoredPoint, Point } from 'src/pages/member/map/visualizer/layers/tools-logic';

export type ToolsType = 'distanceAndArea' | 'drawing' | 'digitalization' | 'editDrawing' | null;
export type MenuType = 'menu' | 'menuClosed' | 'drawingColorSelection' | 'editDrawingColorSelection' | ToolsType;
export type DigitalPinDTO = Point & { value: number };

type SetCurrentToolAndMenuDTO =
  | { tool: Exclude<ToolsType, 'editDrawing'>; menu: MenuType }
  | { tool: 'editDrawing'; menu: MenuType; layerId: number };

type ToolsState = {
  currentTool: ToolsType;
  currentMenu: MenuType;
  colorNumber: number;
  currentEditLayerId: number | null;
  distancePoints: Point[];
  drawPoints: ColoredPoint[];
  digitalizationPins: DigitalPinDTO[];
};

const initialState: ToolsState = {
  currentTool: null,
  currentMenu: 'menuClosed',
  colorNumber: 0,
  currentEditLayerId: null,
  distancePoints: [],
  drawPoints: [],
  digitalizationPins: []
};

const slice = createSlice({
  name: 'tools',
  initialState,
  reducers: {
    initializeTools: (state, action: PayloadAction<ToolsState>) => {
      Object.assign(state, action.payload);
    },
    setCurrentMenu(state, action: PayloadAction<MenuType>) {
      state.currentMenu = action.payload;
    },
    setCurrentToolAndMenu(state, action: PayloadAction<SetCurrentToolAndMenuDTO>) {
      const { tool, menu } = action.payload;
      state.currentTool = tool;
      state.currentMenu = menu;
      if (tool === 'editDrawing') {
        state.currentEditLayerId = action.payload.layerId;
      } else {
        state.currentEditLayerId = null;
      }
    },
    setColorNumber(state, action: PayloadAction<number>) {
      state.colorNumber = action.payload;
    },
    setDistancePoints(state, action: PayloadAction<Point[]>) {
      state.distancePoints = action.payload;
    },
    setDrawingPoints(state, action: PayloadAction<ColoredPoint[]>) {
      state.drawPoints = action.payload;
    },
    setDigitalizationPins(state, action: PayloadAction<DigitalPinDTO[]>) {
      state.digitalizationPins = action.payload;
    },
    reset: () => initialState
  }
});

export const {
  initializeTools,
  setCurrentMenu,
  setCurrentToolAndMenu,
  setColorNumber,
  setDistancePoints,
  setDrawingPoints,
  setDigitalizationPins,
  reset
} = slice.actions;

export const { reducer } = slice;
