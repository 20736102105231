import { useGetMapsQuery } from 'src/slices/layers';
import { useSelector } from 'src/store';
import useSiteId from './useSiteId';
import Layer from 'src/models/layer';
import { useMemo } from 'react';
import { isMobile } from 'src/utils/location';

export enum DefaultLayerIdentifiers {
  points = 0,
  labels = -1,
  kriging = -2
}

const defaultLayers = [
  {
    id: DefaultLayerIdentifiers.points,
    index: 0,
    label: 'points',
    default: true,
    opacity: 1,
    iconName: 'location-dot'
  },
  {
    id: DefaultLayerIdentifiers.labels,
    index: 1,
    label: 'labels',
    default: true,
    opacity: 1,
    iconName: 'font'
  },
  {
    id: DefaultLayerIdentifiers.kriging,
    index: 2,
    label: 'kriging',
    default: true,
    opacity: 1,
    iconName: 'circle-o'
  }
] as Layer[];

export const useLayers = () => {
  const siteId = useSiteId();
  const opacityOverrides = useSelector((store) => store.layers.opacityOverrides);
  const indexOverrides = useSelector((store) => store.layers.indexOverrides);

  const extensions = isMobile() ? 'dxf,shp' : 'dxf,jpg,shp';

  const { data, isFetching } = useGetMapsQuery({ siteId, extensions });

  const layers = useMemo(() => {
    if (!data?.layers) return [];

    const drawingLayers = data?.layers?.filter((layer) => layer.source === 'drawing');
    const importedLayers = data?.layers?.filter((layer) => layer.source !== 'drawing');

    if (drawingLayers.length > 1) drawingLayers.reverse();

    return [...drawingLayers, ...defaultLayers, ...importedLayers]
      .map((layer, index) => {
        const opacityOverride = opacityOverrides.find((o) => o.layerId === layer.id);
        const indexOverride = indexOverrides.find((o) => o.layerId === layer.id);

        return {
          ...layer,
          opacity: opacityOverride?.opacity ?? 1, // fully visible by default
          index: indexOverride?.index ?? index, // list index by default
          iconName: layer.iconName || 'map',
          siteId: siteId // initialization for default layers
        } as Layer;
      })
      .sort((a, b) => a.index - b.index);
  }, [data, opacityOverrides, indexOverrides, siteId]);

  const reversedLayers = useMemo(() => {
    return [...layers].reverse();
  }, [layers]);

  return {
    isFetching: isFetching,
    layers: layers,
    reversedLayers: reversedLayers
  };
};
