// Common
import { request } from '../../common';

// Models
import { AdminOrganizationDto } from './models';

interface RequestRoute {
  id: number;
}

export const getOrganization = (route: RequestRoute): Promise<Response> =>
  request({ method: 'GET', api: `${process.env.REACT_APP_API_URL}/api/organizations/${route.id}`, authorized: true });

interface Response {
  organization: AdminOrganizationDto;
}
