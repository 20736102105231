import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AlertSettingsState {
  tendancyAlertsValues: number[];
  zScoreAlertsValues: number[];
  isDifferentThanInitialState: boolean;
}

export interface InitialSettingsState {
  [key: string]: AlertSettingsState;
}

const initialState: InitialSettingsState = {};

const defaultSettings = {
  tendancyAlertsValues: [-20, -10, 10, 20],
  zScoreAlertsValues: [-5, -2, 2, 5],
  isDifferentThanInitialState: false
};

const slice = createSlice({
  name: 'alert-settings',
  initialState,
  reducers: {
    initializeAlertSettings: (state, action: PayloadAction<InitialSettingsState>) => {
      Object.assign(state, action.payload);
    },
    initializeSettings: (state, action: PayloadAction<{ selectedParameter: string }>) => {
      state[action.payload.selectedParameter] = defaultSettings;
    },
    setTendancyValues(
      state,
      action: PayloadAction<{
        selectedParameter: string;
        tendancyAlertsValues: number[];
      }>
    ) {
      state[action.payload.selectedParameter].tendancyAlertsValues = action.payload.tendancyAlertsValues;
      state[action.payload.selectedParameter].isDifferentThanInitialState = true;
    },
    setZScoreValues(
      state,
      action: PayloadAction<{
        selectedParameter: string;
        zScoreAlertsValues: number[];
      }>
    ) {
      state[action.payload.selectedParameter].zScoreAlertsValues = action.payload.zScoreAlertsValues;
      state[action.payload.selectedParameter].isDifferentThanInitialState = true;
    },
    resetSettings: (state, action: PayloadAction<string>) => {
      state[action.payload] = defaultSettings;
    }
  }
});

export const {
  initializeAlertSettings,
  initializeSettings,
  setTendancyValues,
  setZScoreValues,
  resetSettings
} = slice.actions;

export const { reducer } = slice;
