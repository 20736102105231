import * as d3 from 'd3';

export const formatNumberUtils = (value: number | string, region: string, significantDigits: number = 2): string => {
  if (value === null || value === undefined) {
    return '';
  }

  let numberValue = Number(value);

  if (Math.abs(numberValue) < 1e-300) {
    return '0';
  }

  if (Math.abs(numberValue) <= 1e-10) {
    return numberValue.toExponential(significantDigits);
  }

  if (Math.abs(numberValue) < 10) {
    return numberValue.toLocaleString(region, {
      maximumSignificantDigits: significantDigits
    });
  }

  numberValue = Math.round(numberValue);
  return numberValue.toLocaleString(region, {});
};

export const getThousandSeparator = (countryCode: string) => {
  // Formatter un nombre en utilisant la locale spécifiée
  const formatter = new Intl.NumberFormat(countryCode);

  // Formater un nombre et extraire le séparateur en observant le formatage
  const formattedNumber = formatter.format(1000);

  // Rechercher tout ce qui n'est pas une partie du nombre
  const match = formattedNumber.match(/(\D+)/);

  // Retourner le séparateur s'il existe
  return match ? match[0] : null;
};

export const getDecimalSeparator = (countryCode: string) => {
  // Formatter un nombre avec décimales en utilisant la locale spécifiée
  const formatter = new Intl.NumberFormat(countryCode, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  // Formater un nombre et extraire le séparateur en observant le formatage
  const formattedNumber = formatter.format(1.1);

  // Rechercher le séparateur décimal en trouvant le premier caractère non numérique après l'entier
  const match = formattedNumber.match(/1(\D?)1/);

  // Retourner le séparateur s'il existe
  return match ? match[1] : null;
};

export function formatSignificantFigures(value: number): number {
  return Number(d3.format('.3r')(value));
}
