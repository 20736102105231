import type { FC, PropsWithChildren } from 'react';

// Externals
import { Navigate, useSearchParams } from 'react-router-dom';

// Store
import { useSelector } from 'src/store';

const GuestGuard: FC<PropsWithChildren> = ({ children }) => {
  const [searchParams] = useSearchParams();

  const isAuthenticated = useSelector<boolean>((state) => state.authentication.isAuthenticated);

  if (isAuthenticated) {
    return <Navigate replace={true} to={searchParams.get('redirect') ? searchParams.get('redirect') : '/'} />;
  }

  return <>{children}</>;
};

export default GuestGuard;
