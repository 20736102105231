import { useSearchParams } from 'react-router-dom';

export type ITab = {
  key: string;
  label: string;
  component?: JSX.Element;
};

type TabsProps = {
  defaultTab: ITab;
  tabs: ITab[];
  searchParamKey: string;
};

const useTabs = ({ defaultTab, tabs, searchParamKey }: TabsProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const tabSearchParams = searchParams.get(searchParamKey);

  const handleTabChange = (_, tabId: string) => {
    const tab = tabs.find((tab) => tab.key === tabId) || defaultTab;

    searchParams.set(searchParamKey, tab.key);
    setSearchParams(searchParams, { replace: true });
  };

  return {
    tab: tabs.find((tab) => tab.key === tabSearchParams) || defaultTab,
    handleTabChange: handleTabChange
  };
};

export default useTabs;
