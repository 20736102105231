import { Navigate } from 'react-router-dom';
import useUserLocalStorage from 'src/hooks/use-user-local-storage';
import { useSelector } from 'src/store';
import { useUpdateLastPath } from 'src/hooks/use-update-last-path';
import { SiteRoles } from 'src/models/memberRoles';

const DefaultSiteRedirection = () => {
  const { getItem } = useUserLocalStorage();
  const data = useSelector((state) => state.organization.organizations);
  const organizations = data?.organizations;
  const { updateLastPath } = useUpdateLastPath();

  if (!organizations || organizations.length === 0) {
    return <Navigate replace={true} to={`403`} />;
  }

  const lastOrganizationId = Number(getItem(`organization`));
  const lastSiteId = Number(getItem(`org-${lastOrganizationId}-last-site`));
  const lastGroupId = getItem(`site-${lastSiteId}-last-group`);

  const foundOrganization = organizations.find((organization) => organization.id === lastOrganizationId);
  const foundSite = foundOrganization?.sites.find((site) => site.id === lastSiteId);
  const foundGroup = lastGroupId === '0' || foundSite?.groups.find((g) => g.id === lastGroupId);

  if (foundOrganization && foundSite && foundGroup) {
    return <Navigate replace={true} to={`/${lastOrganizationId}/${lastSiteId}/${lastGroupId}/map`} />;
  }

  const defaultOrganization = organizations.length && organizations[0];
  const defaultSite = defaultOrganization?.sites.length && defaultOrganization?.sites[0];
  const defaultGroup =
    defaultSite?.role === SiteRoles.User ? { id: '0' } : defaultSite?.groups.length && defaultSite?.groups[0];

  if (defaultOrganization && defaultSite && defaultGroup) {
    updateLastPath(defaultOrganization.id, defaultSite.id, defaultGroup.id);

    return <Navigate replace={true} to={`/${defaultOrganization.id}/${defaultSite.id}/${defaultGroup.id}/map`} />;
  }

  return <Navigate replace={true} to={`404`} />;
};

export default DefaultSiteRedirection;
