import { GridRowSelectionModel } from '@mui/x-data-grid-pro';
import api from './api';

// Externals
import { createSlice } from '@reduxjs/toolkit';

//***************************************  STATE  ****************************************//

interface siteGroupState {}

const initialState: siteGroupState = {};

//**********************************  REQUEST & RESULT  **********************************//

export interface DataGroupListItem {
  name: string;
  id: string;
  pointsCount: number;
  parametersCount: number;
  guestMembersCount: number;
}

interface GetSiteGroupsResult {
  dataGroups: DataGroupListItem[];
}

interface GetSiteGroupsRequest {
  siteId: number;
}

export interface DataGroupDetailsDto {
  id: string;
  name: string;
  points: Array<string>;
  parameters: Array<string>;
  members: Array<number>;
  aquiferThickness: number;
  porosity: number;
}

interface GetSiteGroupsDetailsResult {
  group: DataGroupDetailsDto;
}

interface GetSiteGroupsDetailsRequest {
  siteId: number;
  groupId: string;
}

export interface DataGroupCharacteristicsDto {
  name: string;
  aquiferThickness: number;
  porosity: number;
}

interface GetSiteGroupsCharacteristicsResult {
  groupCharacteristics: DataGroupCharacteristicsDto;
}

interface GetSiteGroupsCharacteristicsRequest {
  siteId: number;
  groupId: string;
}

interface AddSiteGroupResult {}

interface AddSiteGroupRequest {
  siteId: number;
  name: string;
}

interface UpdateSiteGroupRequest {
  siteId: number;
  groupId: string;
  name: string;
  parameters: GridRowSelectionModel;
  points: GridRowSelectionModel;
  aquiferThickness?: number | string;
  porosity?: number | string;
}

interface DeleteSiteGroupRequest {
  siteId: number;
  dataGroupId: string;
}

//*****************************************  API  *****************************************//

export const extendedSiteGroupsAPI = api
  .enhanceEndpoints({
    addTagTypes: ['site-groups', 'members-data-me']
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getSiteGroups: builder.query<GetSiteGroupsResult, GetSiteGroupsRequest>({
        query: (args) => ({
          url: `/sites/${args.siteId}/datagroups`,
          method: 'GET'
        }),
        providesTags: ['site-groups']
      }),

      getSiteGroupDetails: builder.query<GetSiteGroupsDetailsResult, GetSiteGroupsDetailsRequest>({
        query: (args) => {
          return {
            url: `/sites/${args.siteId}/datagroups/${args.groupId}/details`,
            method: 'GET'
          };
        },
        providesTags: ['site-groups']
      }),

      getSiteGroupCharacteristics: builder.query<
        GetSiteGroupsCharacteristicsResult,
        GetSiteGroupsCharacteristicsRequest
      >({
        query: (args) => {
          return {
            url: `/sites/${args.siteId}/datagroups/${args.groupId}/characteristics`,
            method: 'GET'
          };
        },
        providesTags: ['site-groups']
      }),

      addSiteGroup: builder.mutation<AddSiteGroupResult, AddSiteGroupRequest>({
        query: (args) => {
          return {
            url: `/sites/${args.siteId}/datagroups`,
            method: 'POST',
            body: { name: args.name }
          };
        },
        invalidatesTags: ['site-groups', 'members-data-me']
      }),

      updateSiteGroup: builder.mutation<void, UpdateSiteGroupRequest>({
        query: (args) => {
          return {
            url: `/sites/${args.siteId}/datagroups/${args.groupId}`,
            method: 'PUT',
            body: {
              name: args.name,
              parameters: args.parameters,
              points: args.points,
              aquiferThickness: args.aquiferThickness,
              porosity: args.porosity
            }
          };
        },
        invalidatesTags: ['site-groups', 'members-data-me']
      }),

      deleteSiteGroup: builder.mutation<void, DeleteSiteGroupRequest>({
        query: (args) => {
          return {
            url: `/sites/${args.siteId}/datagroups/${args.dataGroupId}`,
            method: 'DELETE'
          };
        },
        invalidatesTags: ['site-groups', 'members-data-me']
      })
    })
  });

//****************************************  SLICES  ****************************************//

export const slice = createSlice({
  name: 'site-group',
  initialState,
  reducers: {},
  extraReducers: (builder) => {}
});

//****************************************  THUNKS  ****************************************//

//****************************************  EXPORT  ****************************************//

export const { reducer } = slice;

export const {
  useGetSiteGroupsQuery,
  useGetSiteGroupDetailsQuery,
  useGetSiteGroupCharacteristicsQuery,
  useAddSiteGroupMutation,
  useUpdateSiteGroupMutation,
  useDeleteSiteGroupMutation
} = extendedSiteGroupsAPI;
