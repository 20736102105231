// API
import api from '../slices/api';

// Externals
import { combineReducers } from '@reduxjs/toolkit';

// Slices
import { reducer as authenticationReducer } from '../slices/authentication';
import { reducer as organizationReducer } from '../slices/organization';
import { reducer as siteDataReducer } from '../slices/importdata';
import { reducer as siteGroupReducer } from '../slices/group';
import { reducer as dashboardReducer } from '../slices/dashboard';
import { reducer as krigingReducer } from '../slices/kriging';
import { reducer as tableNavigationReducer } from '../slices/table-navigation';
import { reducer as settingsReducer } from '../slices/alert-settings';
import { reducer as siteConfigReducer } from '../slices/site-config';
import { reducer as layersConfigReducer } from '../slices/layers';
import { reducer as toolsReducer } from '../slices/tools';
import { reducer as flowConfigReducer } from '../slices/flow';

const reducer = combineReducers({
  authentication: authenticationReducer,
  organization: organizationReducer,
  siteData: siteDataReducer,
  siteGroupData: siteGroupReducer,
  dashboard: dashboardReducer,
  tableNavigation: tableNavigationReducer,
  kriging: krigingReducer,
  alertSettings: settingsReducer,
  siteConfig: siteConfigReducer,
  layers: layersConfigReducer,
  tools: toolsReducer,
  flowConfig: flowConfigReducer,
  [api.reducerPath]: api.reducer
});

export default reducer;
