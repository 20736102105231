import type { FC, ReactNode } from 'react';
// Components
import Item from './item';
import type { ListProps } from '@mui/material';
// Externals
import { List, ListSubheader } from '@mui/material';
import { matchPath } from 'react-router-dom';

// Types
type ItemProps = {
  path?: string;
  icon?: ReactNode;
  info?: ReactNode;
  children?: ItemProps[];
  title: string;
};

type SectionProps = ListProps & {
  items: ItemProps[];
  pathname: string;
  title: string;
};

type ReduceChildrenProps = {
  acc: JSX.Element[];
  pathname: string;
  item: ItemProps;
  depth: number;
};

type RenderItemsProps = {
  items: ItemProps[];
  pathname: string;
  depth?: number;
};

const reduceChildren = ({ acc, pathname, item, depth }: ReduceChildrenProps): JSX.Element[] => {
  const key = `${item.title}-${depth}`;
  const exactMatch = item.path
    ? !!matchPath(
        {
          path: item.path,
          end: true
        },
        pathname
      )
    : false;

  if (item.children) {
    const partialMatch = item.path
      ? !!matchPath(
          {
            path: item.path,
            end: false
          },
          pathname
        )
      : false;

    acc.push(
      <Item
        active={partialMatch}
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={partialMatch}
        path={item.path}
        title={item.title}
      >
        {renderItems({
          depth: depth + 1,
          items: item.children,
          pathname
        })}
      </Item>
    );
  } else {
    acc.push(
      <Item
        active={exactMatch}
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        path={item.path}
        title={item.title}
      />
    );
  }

  return acc;
};

const renderItems = ({ depth = 0, items, pathname }: RenderItemsProps): JSX.Element => (
  <List disablePadding>
    {items.reduce<JSX.Element[]>((acc, item) => reduceChildren({ acc, item, pathname, depth }), [])}
  </List>
);

const Section: FC<SectionProps> = ({ items, pathname, title, ...other }) => {
  return (
    <List
      subheader={
        <ListSubheader
          disableGutters
          disableSticky
          sx={{
            color: 'text.primary',
            fontSize: '0.75rem',
            lineHeight: 2.5,
            fontWeight: 700,
            textTransform: 'uppercase'
          }}
        >
          {title}
        </ListSubheader>
      }
      {...other}
    >
      {renderItems({ items, pathname })}
    </List>
  );
};

export default Section;
