import type { FC } from 'react';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import Scrollbar from 'src/components/Scrollbar';
import Section from './section';
import { Box } from '@mui/material';
import { t } from '@lingui/macro';
import { OrganizationRoles } from 'src/models/memberRoles';
import useSiteId from 'src/hooks/useSiteId';
import useOrganizationId from 'src/hooks/useOrganizationId';
import useGroupId from 'src/hooks/useGroupId';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useOrganizationRole } from 'src/hooks/use-role';
import { useSelector } from 'src/store';
import { SIDEBAR_WIDTH } from 'src/constants/styles-constants';
import useUserLocalStorage from 'src/hooks/use-user-local-storage';
import { getFirstSiteId } from 'src/utils/navigation';

const userSections = (organizationId, siteId, groupId, setOpenSettings) => [
  {
    title: t`Display data`,
    items: [
      {
        title: t`Map`,
        path: `/${organizationId}/${siteId}/${groupId}/map`,
        icon: <FontAwesomeIcon icon={['far', 'map-location-dot']} />
      },
      {
        title: t`Cross tables`,
        path: `/${organizationId}/${siteId}/${groupId}/table`,
        icon: <FontAwesomeIcon icon={['far', 'table-cells']} />
      },
      {
        title: t`Manage data`,
        path: `/${organizationId}/${siteId}/${groupId}/data`,
        icon: <FontAwesomeIcon icon={['far', 'database']} />
      }
    ]
  },
  {
    title: t`Settings`,
    items: [
      {
        title: t`Settings`,
        onClick: () => {
          setOpenSettings(true);
        },
        icon: <FontAwesomeIcon icon={['far', 'gear']} />
      }
    ]
  }
];

const adminSections = (organizationId, siteId, groupId, setOpenSettings) => [
  {
    title: t`Display data`,
    items: [
      {
        title: t`Map`,
        path: `/${organizationId}/${siteId}/${groupId}/map`,
        icon: <FontAwesomeIcon icon={['far', 'map-location-dot']} />
      },
      {
        title: t`Measurements`,
        path: `/${organizationId}/${siteId}/${groupId}/table`,
        icon: <FontAwesomeIcon icon={['far', 'table-cells']} />
      }
    ]
  },
  {
    title: t`Administrator`,
    items: [
      {
        title: t`Points and parameters`,
        path: `/${organizationId}/${siteId}/${groupId}/data`,
        icon: <FontAwesomeIcon icon={['far', 'database']} />
      },
      {
        title: t`Import measurements`,
        path: `/${organizationId}/${siteId}/import`,
        icon: <FontAwesomeIcon icon={['far', 'down-to-dotted-line']} />
      },
      {
        title: t`Manage groups`,
        path: `/${organizationId}/${siteId}/groups`,
        icon: <FontAwesomeIcon icon={['far', 'object-intersect']} />
      },
      {
        title: t`Share`,
        path: `/${organizationId}/${siteId}/share`,
        icon: <FontAwesomeIcon icon={['far', 'share-square']} />
      }
    ]
  },
  {
    title: t`Settings`,
    items: [
      {
        title: t`General settings`,
        onClick: () => {
          setOpenSettings(true);
        },
        icon: <FontAwesomeIcon icon={['far', 'gear']} />
      }
    ]
  }
];

type DashboardSidebarProps = {
  setOpenSettings: (value: boolean) => void;
};

const DashboardSidebar: FC<DashboardSidebarProps> = ({ setOpenSettings }) => {
  const location = useLocation();
  const { getItem } = useUserLocalStorage();

  const data = useSelector((state) => state.organization.organizations);

  const localStorageOrganizationId = Number(getItem(`organization`));
  const localStorageSiteId = Number(getItem(`site`));
  const localStorageGroupId = getItem(`group`);

  const organizationId = useOrganizationId() || localStorageOrganizationId;
  const siteId = useSiteId() || localStorageSiteId || (organizationId && getFirstSiteId(data, organizationId));
  const groupId = useGroupId() || localStorageGroupId || 0;

  const role = useOrganizationRole();

  const sections = useMemo(() => {
    switch (role) {
      case OrganizationRoles.Administrator:
        return adminSections(organizationId, siteId, groupId, setOpenSettings);
      case OrganizationRoles.Member:
        return userSections(organizationId, siteId, groupId, setOpenSettings);
      default:
        return [];
    }
  }, [role, organizationId, groupId, siteId, setOpenSettings]);

  if (sections.length === 0) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          borderRight: '1px solid #e0e0e0'
        }}
      >
        <Scrollbar options={{ suppressScrollX: true }}>
          <Box sx={{ p: 2 }}>
            <Section
              title=""
              items={[
                { loading: true, title: t`Loading...` },
                { loading: true, title: t`Loading...` },
                { loading: true, title: t`Loading...` },
                { loading: true, title: t`Loading...` }
              ]}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  borderTop: '1px solid #e0e0e0'
                }
              }}
            />
          </Box>
        </Scrollbar>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        borderRight: '1px solid #e0e0e0',
        width: SIDEBAR_WIDTH
      }}
    >
      <Box
        sx={{
          p: 2,
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          '& > :not(:first-of-type):last-child': {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end'
          },
          '& > :not(:first-of-type):not(:last-child)': {
            borderTop: '1px solid #e0e0e0'
          }
        }}
      >
        {sections.map((section) => (
          <Section key={section.title} pathname={location.pathname} {...section} />
        ))}
      </Box>
    </Box>
  );
};

export default DashboardSidebar;
