import { GridColumnMenu, GridColumnMenuProps } from '@mui/x-data-grid';

export const CustomColumnMenu = (props: GridColumnMenuProps) => {
  return (
    <GridColumnMenu
      {...props}
      slots={{
        columnMenuColumnsItem: null,
        columnMenuPinningItem: null
      }}
    />
  );
};
