import { FC, PropsWithChildren, useEffect, useState } from 'react';

// Externals
import { Navigate, useLocation, useSearchParams } from 'react-router-dom';
import { useMeQuery } from 'src/slices/authentication';

// Store
import { useSelector } from 'src/store';
import SplashScreen from '../../screens/SplashScreen';
import { type UserRoles as UserRolesType, UserRoles } from 'src/models/memberRoles';

type AuthGuardProps = {
  role: UserRolesType;
};

const AuthGuard: FC<PropsWithChildren<AuthGuardProps>> = ({ children, role }) => {
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const token = localStorage.getItem('token');
  const { isLoading } = useMeQuery(null, { refetchOnMountOrArgChange: true, skip: !token });

  const isAuthenticated = useSelector((state) => state.authentication.isAuthenticated);
  const userRole = useSelector((state) => state.authentication.role);
  const [showSplashscreen, setShowSplashscreen] = useState(true);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowSplashscreen(false);
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, []);

  if (showSplashscreen || isLoading) {
    return <SplashScreen />;
  }

  if (!isAuthenticated)
    return <Navigate replace={true} to={`/auth/signin?redirect=${encodeURIComponent(location.pathname)}`} />;

  const redirectPath = searchParams.get('redirect');
  if (redirectPath && location.pathname !== redirectPath) return <Navigate replace={true} to={redirectPath} />;

  if (userRole && role !== userRole) {
    if (userRole === UserRoles.SuperAdministrator) {
      return <Navigate replace={true} to="/administration" />;
    } else {
      return <Navigate replace={true} to="/" />;
    }
  }

  return <>{children}</>;
};

export default AuthGuard;
