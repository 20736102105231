import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ScaleType = 'log' | 'linear';
export type AggregationType = 'last' | 'min' | 'max' | 'first' | 'avg';
export type MagnitudePrecisionType = 'full' | 'half' | 'third';
export type ValueLimitType = 'site' | 'group' | 'dataset' | 'custom';

export type KrigingOption = {
  scale: ScaleType;
  aggregation: AggregationType;
  precision: MagnitudePrecisionType;
  valueLimit: ValueLimitType | null;
  customValueLimitMin: number | null;
  customValueLimitMax: number | null;
  colorMode: 'default' | '2-colors' | '3-colors';
  minColor: string;
  maxColor: string;
  step: {
    color: string;
    value: number | null;
    percentage: number | null;
  };
  userId: string;
  parameterId: string;
};

export interface KrigingState {
  scale: ScaleType;
  valueSelection: AggregationType;
  alpha: number;
  model: 'gaussian' | 'spherical' | 'exponential' | 'linear';
  variance: number;
  pixelSize: number;
  precision: MagnitudePrecisionType;
  options: KrigingOption[];
}

const initialState: KrigingState = {
  scale: 'log',
  valueSelection: 'last',
  model: 'linear',
  variance: 0,
  alpha: 100,
  pixelSize: 10,
  precision: 'third',
  options: []
};

const slice = createSlice({
  name: 'kriging',
  initialState,
  reducers: {
    initializeKriging: (state, action: PayloadAction<KrigingState>) => {
      Object.assign(state, action.payload);
    },
    setKrigingValues: (
      state,
      action: PayloadAction<{ scale: ScaleType; aggregation: AggregationType; precision: MagnitudePrecisionType }>
    ) => {
      state.scale = action.payload.scale;
      state.valueSelection = action.payload.aggregation;
      state.precision = action.payload.precision;
    },
    addOrUpdateOption: (state, action: PayloadAction<KrigingOption>) => {
      state.options = [
        ...state.options.filter(
          (o) => !(o.parameterId === action.payload.parameterId && o.userId === action.payload.userId)
        ),
        {
          parameterId: action.payload.parameterId,
          scale: action.payload.scale,
          aggregation: action.payload.aggregation,
          customValueLimitMin: action.payload.customValueLimitMin,
          customValueLimitMax: action.payload.customValueLimitMax,
          colorMode: action.payload.colorMode,
          minColor: action.payload.minColor,
          maxColor: action.payload.maxColor,
          step: action.payload.step,
          valueLimit: action.payload.valueLimit,
          precision: action.payload.precision,
          userId: action.payload.userId
        }
      ];
    }
  }
});

export const { initializeKriging, addOrUpdateOption, setKrigingValues } = slice.actions;

export const { reducer } = slice;
