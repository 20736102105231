import MobileLayout from './mobile-layout';
import DashboardLayout from './dashboard-layout';
import ScreenSizeGuard from 'src/components/guards/screen-size-guard';

const ResponsiveLayout = () => (
  <>
    <ScreenSizeGuard>
      <MobileLayout />
    </ScreenSizeGuard>
    <ScreenSizeGuard allow="large">
      <DashboardLayout />
    </ScreenSizeGuard>
  </>
);

export default ResponsiveLayout;
