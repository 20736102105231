import { Trans } from '@lingui/macro';
import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import useGroupId from 'src/hooks/useGroupId';
import useSiteId from 'src/hooks/useSiteId';
import { useGetSiteParametersQuery } from 'src/slices/importdata';

interface UnitConverterCellProps {
  sourceValue: string;
  normalizedValue: string;
  sourceUnit: string;
  normalizedParameterId: string;
  underDetectionLimit?: boolean;
}

const UnitConverterCell: FC<UnitConverterCellProps> = ({
  sourceValue,
  normalizedValue,
  sourceUnit,
  normalizedParameterId,
  underDetectionLimit
}) => {
  const siteId = useSiteId();
  const groupId = useGroupId();

  const { data: siteParameters } = useGetSiteParametersQuery({ siteId, groupId });

  const parameter = siteParameters?.parameters.find((p) => p.id === normalizedParameterId);

  return (
    <Box>
      <Typography>
        <Trans>Source</Trans> : {underDetectionLimit && '<'} {sourceValue} {sourceUnit}
      </Typography>
      <Typography>
        <Trans>Normalized</Trans> : {underDetectionLimit && '<'} {normalizedValue} {parameter?.units}
      </Typography>
    </Box>
  );
};

export default UnitConverterCell;
