import { plural, t, Trans } from '@lingui/macro';
import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Dispatch, FC, SetStateAction } from 'react';
import toast from 'react-hot-toast';
import useSiteId from 'src/hooks/useSiteId';
import { useRestoreMeasuresMutation } from 'src/slices/importdata';

interface RestoreMeasuresDialogProps {
  openConfirmRestoreDialog: boolean;
  setOpenConfirmRestoreDialog: Dispatch<SetStateAction<boolean>>;
  selectedRowIds: number[];
  setSelectedRowIds: Dispatch<SetStateAction<number[]>>;
}

const RestoreMeasuresDialog: FC<RestoreMeasuresDialogProps> = ({
  openConfirmRestoreDialog,
  setOpenConfirmRestoreDialog,
  selectedRowIds,
  setSelectedRowIds
}) => {
  const siteId = useSiteId();

  const [restoreMeasures, { isLoading: isRestoreMeasuresLoading }] = useRestoreMeasuresMutation();

  return (
    <Dialog
      open={openConfirmRestoreDialog}
      onClose={() => setOpenConfirmRestoreDialog(false)}
      PaperProps={{ style: { width: '100%' } }}
    >
      <DialogTitle>
        <Trans>Confirm restoration</Trans>
      </DialogTitle>
      <DialogContent>
        {plural(selectedRowIds.length, {
          one: 'Are you sure you want to restore this measurement?',
          other: 'Are you sure you want to restore these # measurements?'
        })}
      </DialogContent>
      <DialogActions sx={{ flexDirection: 'row', gap: 1, paddingX: 3, paddingBottom: 3 }}>
        <Button
          variant="outlined"
          onClick={() => {
            setOpenConfirmRestoreDialog(false);
          }}
        >
          <Trans>Cancel</Trans>
        </Button>
        <LoadingButton
          loading={isRestoreMeasuresLoading}
          variant="contained"
          onClick={async () => {
            try {
              await restoreMeasures({
                siteId: siteId,
                measureIdsToRestore: selectedRowIds
              });
              setSelectedRowIds([]);
            } catch (e) {
              console.log(e);
              toast.error(t`An error occured while restoring measurements.`);
            }

            setOpenConfirmRestoreDialog(false);
          }}
        >
          <Trans>Confirm</Trans>
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default RestoreMeasuresDialog;
