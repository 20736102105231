import { t } from '@lingui/macro';

const useRegionOptions = () => {
  return [
    { value: 'fr-FR', label: t`France` },
    { value: 'fr-BE', label: t`Belgium (French)` },
    { value: 'nl-BE', label: t`Belgium (Dutch)` },
    { value: 'de-BE', label: t`Belgium (German)` },
    { value: 'nl-NL', label: t`Netherlands` },
    { value: 'de-DE', label: t`Germany` },
    { value: 'de-AT', label: t`Austria` },
    { value: 'de-CH', label: t`Switzerland (German)` },
    { value: 'it-IT', label: t`Italy` },
    { value: 'en-GB', label: t`United Kingdom` },
    { value: 'en-IE', label: t`Ireland` },
    { value: 'en-US', label: t`United States` },
    { value: 'en-CA', label: t`Canada (English)` },
    { value: 'fr-CA', label: t`Canada (French)` },
    { value: 'es-ES', label: t`Spain` },
    { value: 'es-MX', label: t`Mexico` },
    { value: 'pt-PT', label: t`Portugal` },
    { value: 'pt-BR', label: t`Brazil` },
    { value: 'zh-CN', label: t`China (Simplified Chinese)` },
    { value: 'zh-TW', label: t`Taiwan (Traditional Chinese)` },
    { value: 'ja-JP', label: t`Japan` },
    { value: 'ko-KR', label: t`South Korea` },
    { value: 'ru-RU', label: t`Russia` },
    { value: 'ar-SA', label: t`Saudi Arabia` },
    { value: 'he-IL', label: t`Israel` },
    { value: 'tr-TR', label: t`Turkey` },
    { value: 'el-GR', label: t`Greece` },
    { value: 'pl-PL', label: t`Poland` },
    { value: 'sv-SE', label: t`Sweden` },
    { value: 'nb-NO', label: t`Norway` },
    { value: 'da-DK', label: t`Denmark` },
    { value: 'fi-FI', label: t`Finland` },
    { value: 'cs-CZ', label: t`Czech Republic` },
    { value: 'hu-HU', label: t`Hungary` },
    { value: 'sk-SK', label: t`Slovakia` },
    { value: 'ro-RO', label: t`Romania` },
    { value: 'bg-BG', label: t`Bulgaria` },
    { value: 'hr-HR', label: t`Croatia` },
    { value: 'sr-RS', label: t`Serbia` },
    { value: 'uk-UA', label: t`Ukraine` },
    { value: 'th-TH', label: t`Thailand` },
    { value: 'vi-VN', label: t`Vietnam` },
    { value: 'ms-MY', label: t`Malaysia` },
    { value: 'id-ID', label: t`Indonesia` },
    { value: 'fa-IR', label: t`Iran` },
    { value: 'hi-IN', label: t`India (Hindi)` },
    { value: 'bn-IN', label: t`India (Bengali)` },
    { value: 'ta-IN', label: t`India (Tamil)` },
    { value: 'ur-PK', label: t`Pakistan` }
  ];
};

export default useRegionOptions;
