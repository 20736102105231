import { useEffect, useState } from 'react';
import MobileNavbar from './navbar';
import { Outlet } from 'react-router-dom';
import { NAVBAR_HEIGHT } from 'src/constants/styles-constants';

const MobileLayout = () => {
  const [viewHeight, setViewHeight] = useState<number>(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setViewHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);

    // Initial setting
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
    };
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: `${viewHeight}px`,
        width: '100vw',
        backgroundColor: '#fff',
        overflow: 'hidden',
        WebkitOverflowScrolling: 'touch',
        paddingBottom: 'env(safe-area-inset-bottom)'
      }}
    >
      <MobileNavbar />
      <div
        style={{
          height: `calc(${viewHeight}px - ${NAVBAR_HEIGHT})`,
          width: '100%',
          position: 'relative',
          top: NAVBAR_HEIGHT,
          overflow: 'auto',
          backgroundColor: 'inherit'
        }}
      >
        <Outlet />
      </div>
    </div>
  );
};

export default MobileLayout;
