import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { InfoOutlined, LockOutlined } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { useRegisterMutation } from 'src/slices/authentication';
import { t, Trans } from '@lingui/macro';
import AuthorizedRequired from '../../AuthorizationRequired';

// Define Zod schema for form validation
const signUpSchema = z
  .object({
    email: z.string().email({ message: t`Invalid email address` }),
    password: z
      .string()
      .min(6, { message: t`Password must be at least 6 characters long` })
      .regex(/(?=.*[a-z])/, { message: t`Password must contain a lower case character` })
      .regex(/(?=.*[A-Z])/, { message: t`Password must contain an upper case character` })
      .regex(/(?=.*\d)/, { message: t`Password must contain a digit` })
      .regex(/(?=.*[^a-zA-Z\d])/, { message: t`Password must contain a non-alphanumeric character` }),
    confirmedPassword: z.string().min(6, { message: t`Password must be at least 6 characters long` }),
    accepted: z.boolean().refine((val) => val === true, {
      message: t`You must accept the terms of use`
    })
  })
  .refine((data) => data.password === data.confirmedPassword, {
    message: t`Passwords don't match`,
    path: ['confirmedPassword']
  });

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://www.krigis.tech/">
        Krigis
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function SignUp() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const invitationId = searchParams.get('invitationId');
  const email = searchParams.get('email');

  const [register] = useRegisterMutation();

  const {
    control,
    handleSubmit,
    formState: { isValid, errors, isSubmitting }
  } = useForm({
    resolver: zodResolver(signUpSchema),
    defaultValues: {
      email: email || '',
      password: '',
      confirmedPassword: '',
      accepted: false
    },
    mode: 'onChange'
  });

  const onSubmit = async (data: any) => {
    try {
      await register({
        email: data.email,
        password: data.password,
        invitationId
      }).unwrap();

      navigate(`/auth/signin`);
    } catch (error) {
      switch (error.data.type) {
        case 'email-already-used':
          toast.error(t`Email already used`);
          break;
        case 'user-register-invalid-password-format':
          toast.error(t`The provided password doesn't respect our security scheme.`);
          break;
        case 'user-register-invalid':
          toast.error(t`Something went wrong with the register.`);
          break;
        case 'invalid-invitation':
          toast.error(t`Invalid invitation`);
          break;
        case 'invitation-id-missing':
          toast.error(t`Missing invitation id`);
          break;
        default:
          toast.error(error?.data?.message || t`Something went wrong.`);
          break;
      }
    }
  };

  if (!invitationId || !email) {
    return <AuthorizedRequired />;
  }

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlined />
        </Avatar>
        <Typography component="h1" variant="h5">
          <Trans>Sign up</Trans>
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    fullWidth
                    id="email"
                    label={t`Email Address`}
                    autoComplete="email"
                    error={!!errors.email}
                    helperText={errors.email?.message}
                    disabled
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                      {...field}
                      required
                      fullWidth
                      name="password"
                      label={t`Password`}
                      type="password"
                      id="password"
                      autoComplete="new-password"
                      error={!!errors.password}
                      helperText={errors.password?.message}
                    />
                    <Tooltip
                      title={
                        <Typography variant="body2">
                          <Trans>
                            Password must be at least 6 characters long, include an upper case letter, a lower case
                            letter, a digit, and a special character.
                          </Trans>
                        </Typography>
                      }
                      placement="right"
                    >
                      <IconButton sx={{ ml: 1 }}>
                        <InfoOutlined />
                      </IconButton>
                    </Tooltip>
                  </Box>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="confirmedPassword"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    fullWidth
                    name="confirmPassword"
                    label={t`Confirm Password`}
                    type="password"
                    id="confirmPassword"
                    autoComplete="new-password"
                    error={!!errors.confirmedPassword}
                    helperText={errors.confirmedPassword?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="accepted"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox {...field} color="primary" />}
                    label={t`I accept the terms of use.`}
                  />
                )}
              />
              {errors.accepted && (
                <Typography variant="body2" color="error">
                  {errors.accepted.message}
                </Typography>
              )}
            </Grid>
          </Grid>
          <LoadingButton
            loading={isSubmitting}
            disabled={isSubmitting || !isValid}
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            <Trans>Sign Up</Trans>
          </LoadingButton>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link
                href={`/auth/signin?redirect=${encodeURIComponent(searchParams.get('redirect') || '/')}`}
                variant="body2"
              >
                <Trans>Already have an account? Sign in</Trans>
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Copyright sx={{ mt: 5 }} />
    </Container>
  );
}
