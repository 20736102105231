import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import api from './api';
import Layer, { LayerSource, LayerType, LayerExtension } from 'src/models/layer';
import { DefaultLayerIdentifiers } from 'src/hooks/useLayers';

interface GetMapsRequest {
  siteId: number;
  extensions?: string;
}

interface GetMapsResult {
  layers: Layer[];
}

interface DeleteLayerRequest {
  siteId: number;
  layerId: number;
}

interface AddLayerRequest {
  label?: string;
  format: LayerExtension;
  file: File;
  siteId: number;
  scaleX?: string;
  scaleY?: string;
  translateX?: string;
  translateY?: string;
  rotateX?: string;
  rotateY?: string;
  type: LayerType;
  source?: LayerSource;
}

interface UpdateDrawingLayerRequest {
  siteId: number;
  layerId: number;
  file: File;
}

interface ConvertLayerToMonitoringAreaRequest {
  siteId: number;
  layerId: number;
}

interface ConvertLayerToMonitoringAreaResponse {}

export const extendedLayerAPI = api
  .enhanceEndpoints({ addTagTypes: ['site-points', 'site-parameters', 'site-layers'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getMaps: builder.query<GetMapsResult, GetMapsRequest>({
        query: (args) => ({
          url: `/sites/${args.siteId}/layers`,
          method: 'GET',
          params: {
            extensions: args.extensions
          }
        }),
        providesTags: ['site-layers']
      }),
      convertLayerToMonitoringArea: builder.mutation<
        ConvertLayerToMonitoringAreaResponse,
        ConvertLayerToMonitoringAreaRequest
      >({
        query: (args) => ({
          url: `sites/${args.siteId}/layers/${args.layerId}/to-monitoring-area`,
          method: 'PUT'
        }),
        invalidatesTags: ['site-layers']
      }),
      addLayer: builder.mutation<void, AddLayerRequest>({
        query: (args) => {
          const formData = new FormData();
          formData.append('file', args.file);
          args.label && formData.append('label', args.label);
          formData.append('format', args.format);
          formData.append('scaleX', args.scaleX || '1');
          formData.append('scaleY', args.scaleY || '1');
          formData.append('translateX', args.translateX || '0');
          formData.append('translateY', args.translateY || '0');
          formData.append('rotateX', args.rotateX || '0');
          formData.append('rotateY', args.rotateY || '0');
          formData.append('type', args.type);
          formData.append('source', args.source || 'upload');

          return {
            url: `/sites/${args.siteId}/layers`,
            method: 'POST',
            body: formData,
            formData: true
          };
        },
        invalidatesTags: ['site-layers']
      }),
      updateDrawingLayer: builder.mutation<void, UpdateDrawingLayerRequest>({
        query: (args) => {
          const formData = new FormData();
          formData.append('file', args.file);

          return {
            url: `/sites/${args.siteId}/layers/${args.layerId}/drawing`,
            method: 'PUT',
            body: formData,
            formData: true
          };
        },
        invalidatesTags: ['site-layers']
      }),
      deleteLayer: builder.mutation<void, DeleteLayerRequest>({
        query: (args) => ({
          url: `/sites/${args.siteId}/layers`,
          method: 'DELETE',
          body: {
            layerId: args.layerId
          }
        }),
        invalidatesTags: ['site-layers']
      })
    })
  });

/****** REDUCERS *******/

interface IndexOverride {
  index: number;
  layerId: number;
}

interface OpacityOverride {
  opacity: number;
  layerId: number;
}

export interface LayersState {
  showAlerts: boolean;
  showPoints: boolean;
  showLabels: boolean;
  showFlow: boolean;
  showKriging: boolean;
  indexOverrides: IndexOverride[];
  opacityOverrides: OpacityOverride[];
}

const initialState: LayersState = {
  showAlerts: false,
  showPoints: true,
  showLabels: true,
  showFlow: false,
  showKriging: false,
  indexOverrides: [],
  opacityOverrides: []
};

const slice = createSlice({
  name: 'layers',
  initialState,
  reducers: {
    initializeLayers: (state, action: PayloadAction<LayersState>) => {
      Object.assign(state, action.payload);
    },
    toggleShowAlerts: (state) => {
      state.showAlerts = !state.showAlerts;
    },
    toggleShowFlow: (state) => {
      state.showFlow = !state.showFlow;
    },
    toggleShowKriging: (state) => {
      if (state.showKriging) {
        const krigingLayer = state.opacityOverrides.find((lo) => lo.layerId === DefaultLayerIdentifiers.kriging);
        if (krigingLayer?.opacity <= 0.1) {
          state.opacityOverrides = [
            ...state.opacityOverrides.filter((lo) => lo.layerId !== DefaultLayerIdentifiers.kriging),
            {
              opacity: 1,
              layerId: DefaultLayerIdentifiers.kriging
            }
          ];
        }
      }
      state.showKriging = !state.showKriging;
    },
    overrideLayerOpacity: (state, action: PayloadAction<{ opacity: number; layerId: number }>) => {
      state.opacityOverrides = [
        ...state.opacityOverrides.filter((lo) => lo.layerId !== action.payload.layerId),
        {
          opacity: action.payload.opacity,
          layerId: action.payload.layerId
        }
      ];
    },
    overrideLayerIndexes: (state, action: PayloadAction<Layer[]>) => {
      state.indexOverrides = [
        ...action.payload.map((layer, index) => ({
          index: index,
          layerId: layer.id
        }))
      ];
    }
  }
});

export const { reducer } = slice;

export const {
  initializeLayers,
  toggleShowAlerts,
  toggleShowKriging,
  overrideLayerOpacity,
  overrideLayerIndexes,
  toggleShowFlow
} = slice.actions;

export const {
  useGetMapsQuery,
  useAddLayerMutation,
  useUpdateDrawingLayerMutation,
  useConvertLayerToMonitoringAreaMutation,
  useDeleteLayerMutation
} = extendedLayerAPI;
