import { FC, useState, MouseEvent } from 'react';
import { Button, Grid, Icon, IconButton, Tooltip } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import AddMemberDialog from './dialogs/addMember';
import EditMemberDialog from './dialogs/editMember';
import DeleteMemberDialog from './dialogs/deleteMember';
import { Plural, t, Trans } from '@lingui/macro';
import { OrganizationMemberDto, useGetMembersQuery } from 'src/slices/organization';
import useOrganizationId from 'src/hooks/useOrganizationId';
import PersistentGrid from 'src/components/persistent-grid';
import { useSelector } from 'src/store';
import { OrganizationRoles } from 'src/models/memberRoles';

type OrganizationMembersProps = {
  organizationId: number;
};

const OrganizationMembersManagement: FC<OrganizationMembersProps> = () => {
  const [member, setMember] = useState<OrganizationMemberDto>();

  const [addMemberDialogOpen, setAddMemberDialogOpen] = useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [editDialogOpen, setEditDialogOpen] = useState<boolean>(false);
  const organizationId = useOrganizationId();
  const { data: membersData } = useGetMembersQuery({ organizationId });
  const members = membersData?.members ?? [];
  const userEmail = useSelector((state) => state?.authentication?.user?.email);

  const deleteMemberHandler = (
    event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
    member: OrganizationMemberDto
  ) => {
    event.stopPropagation();
    setMember(member);
    setDeleteDialogOpen(true);
  };

  const editMemberHandler = (
    event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
    member: OrganizationMemberDto
  ) => {
    event.stopPropagation();
    setMember(member);
    setEditDialogOpen(true);
  };

  const columns: GridColDef<OrganizationMemberDto>[] = [
    { field: 'email', headerName: t`Email`, width: 200 },
    {
      field: 'role',
      headerName: t`Role`,
      width: 150,
      valueGetter: (params) => (params.row.role === OrganizationRoles.Administrator ? t`Administrator` : t`Member`)
    },
    {
      field: 'sites',
      headerName: t`Accesses`,
      width: 150,
      renderCell: (params) => {
        const siteCount = params.row.sites ? params.row.sites.length : 0;
        return <Plural value={siteCount} one="# site" other="# sites" />;
      }
    },
    {
      field: 'actions',
      headerName: t`Actions`,
      width: 150,
      renderCell: ({ row }) => (
        <>
          <Tooltip title={row.email === userEmail && t`You cannot edit yourself`} arrow placement="left">
            <span>
              <IconButton
                title={t`Edit`}
                onClick={(event) => editMemberHandler(event, row)}
                disabled={row.email === userEmail}
              >
                <Icon>edit</Icon>
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title={row.email === userEmail && t`You cannot delete yourself`} arrow placement="left">
            <span>
              <IconButton
                title={t`Delete`}
                onClick={(event) => deleteMemberHandler(event, row)}
                disabled={row.email === userEmail}
              >
                <Icon>delete</Icon>
              </IconButton>
            </span>
          </Tooltip>
        </>
      )
    }
  ];

  return (
    <>
      <Grid gap={1} direction="row" justifyContent="end" my={1} display="flex">
        <Button color="primary" variant="contained" onClick={() => setAddMemberDialogOpen(true)}>
          <Trans>Add</Trans>
        </Button>
      </Grid>
      <PersistentGrid
        persistentKey="organization-members"
        rows={members}
        columns={columns}
        disableRowSelectionOnClick
      />

      {addMemberDialogOpen && (
        <AddMemberDialog
          onCancel={() => {
            setAddMemberDialogOpen(false);
          }}
          onClose={() => {
            setAddMemberDialogOpen(false);
          }}
          onComplete={() => {
            setAddMemberDialogOpen(false);
          }}
        />
      )}

      {editDialogOpen && (
        <EditMemberDialog
          member={member}
          onCancel={() => {
            setEditDialogOpen(false);
          }}
          onClose={() => {
            setEditDialogOpen(false);
          }}
          onComplete={() => {
            setEditDialogOpen(false);
          }}
        />
      )}

      <DeleteMemberDialog
        member={member}
        isOpen={deleteDialogOpen}
        onCancel={() => {
          setDeleteDialogOpen(false);
        }}
        onClose={() => {
          setDeleteDialogOpen(false);
        }}
        onComplete={() => {
          setDeleteDialogOpen(false);
        }}
      />
    </>
  );
};

export default OrganizationMembersManagement;
