import { FC, PropsWithChildren } from 'react';
import { Box } from '@mui/material';
import type { ScrollBarProps } from 'react-perfect-scrollbar';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { isMobile } from 'src/utils/location';

const Scrollbar: FC<PropsWithChildren<ScrollBarProps>> = ({ children, ...props }) => {
  if (isMobile()) {
    return <Box sx={{ overflowX: 'auto' }}>{children}</Box>;
  }

  return <PerfectScrollbar {...props}>{children}</PerfectScrollbar>;
};

export default Scrollbar;
