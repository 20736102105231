import { FC, PropsWithChildren } from 'react';
import { isMobile } from 'src/utils/location';

type ScreenSizeGuardProps = {
  allow?: 'small' | 'large';
};

const ScreenSizeGuard: FC<PropsWithChildren<ScreenSizeGuardProps>> = ({ children, allow = 'small' }) => {
  const isMobileView = isMobile();

  // If we allow only small screens, we return null if the screen is large
  if (allow === 'small' && !isMobileView) return null;

  // If we allow only large screens, we return null if the screen is small
  if (allow === 'large' && isMobileView) return null;

  return <>{children}</>;
};

export default ScreenSizeGuard;
