// Facteurs de conversion
const METERS_PER_DEGREE_LAT = 111000; // 1 degré de latitude ~111 km
const METERS_PER_DEGREE_LON = 73500; // 1 degré de longitude ~73.5 km à la latitude de 48.08

// Degrés par mètre
const DEGREES_LAT_PER_METER = 1 / METERS_PER_DEGREE_LAT;
const DEGREES_LON_PER_METER = 1 / METERS_PER_DEGREE_LON;

export const convertLatLonToXY = (
  userLatitude: number,
  userLongitude: number,
  latitude: number,
  longitude: number,
  x_ref: number,
  y_ref: number
) => {
  const deltaX = (userLongitude - longitude) / DEGREES_LON_PER_METER;
  const deltaY = (userLatitude - latitude) / DEGREES_LAT_PER_METER;

  // Application de l'offset
  const x = x_ref + deltaX;
  const y = y_ref + deltaY;

  return { x, y };
};

export const isMobile = () => {
  const hasTouchScreen = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
  const userAgent = navigator.userAgent || navigator.vendor;
  const isMobileUserAgent = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
  const isSmallScreen =
    window.matchMedia('(max-width: 600px)').matches || window.matchMedia('(max-height: 600px)').matches;

  let mobileConditionsMet = 0;

  if (hasTouchScreen) {
    mobileConditionsMet++;
  }
  if (isMobileUserAgent) {
    mobileConditionsMet++;
  }
  if (isSmallScreen) {
    mobileConditionsMet++;
  }

  return mobileConditionsMet >= 2;
};

export const watchLocation = (
  success: (position: GeolocationPosition) => void,
  error: (error: GeolocationPositionError) => void,
  options?: PositionOptions
): number | null => {
  if (!isMobile()) {
    console.info('Geolocation is supported only on mobile devices.');
    return null;
  }

  if (!navigator.geolocation) {
    console.error('Geolocation is not supported by your browser.');
    return null;
  }

  return navigator.geolocation.watchPosition(success, error, options);
};

export const getLocation = (
  success: (position: GeolocationPosition) => void,
  error: (error: GeolocationPositionError) => void
): void => {
  if (!isMobile()) {
    console.info('Geolocation is supported only on mobile devices.');
    return;
  }

  if (!navigator.geolocation) {
    console.error('Geolocation is not supported by your browser.');
    return;
  }

  navigator.geolocation.getCurrentPosition(success, error);
};

export const clearLocationWatch = (id: number) => {
  navigator.geolocation.clearWatch(id);
};
