import PersistentGrid from 'src/components/persistent-grid';
import CustomTableToolbar from '../../../table/components/CustomTableToolbar';
import { GridCellParams, GridColDef, GridRowsProp } from '@mui/x-data-grid-pro';
import { t } from '@lingui/macro';
import { FC, useMemo, useState } from 'react';
import NormalizationCell from '../../../table/components/all-data-table/NormalizationCell';
import UnitConverterCell from '../../../table/components/all-data-table/UnitConverterCell';
import { formatNumberUtils } from 'src/utils/format-number';
import { useGetSiteParametersQuery } from 'src/slices/importdata';
import useSiteId from 'src/hooks/useSiteId';
import useGroupId from 'src/hooks/useGroupId';
import { CustomColumnMenu } from 'src/components/CustomColumnMenu';
import { useDispatch, useSelector } from 'src/store';
import { setSelectedRange, useGetMeasuresQuery } from 'src/slices/dashboard';
import moment from 'moment';
import { setSelectedParameter, setSelectedPoint, setSelectedView } from 'src/slices/table-navigation';
import DeletedMeasuresActions from './deleted-measures-actions';
import { FormattedDate } from 'src/components/formaters/formatted-date';

interface CustomGridSortModel {
  field: string;
  sort: 'asc' | 'desc';
}

interface RowModel {
  id: number;
  date: Date;
  sourcePointName: string;
  pointName: string;
  sourceParameterName: string;
  parameterName: string;
  parameterId: string;
  sourceUnitName: string;
  unitName: string;
  sourceAmount: string;
  amount: number;
  underDetectionLimit: boolean;
  comments: string;
  analysisId: string;
  sampleId: string;
  laboratoryName: string;
  measureImportFileName: string;
  sourceImportedAt: Date;
  sourceConfirmedAt: Date;
  sourceConfirmedBy: string;
  isDeleted: boolean;
  deleteBy?: string;
  deletedAt?: Date;
  deleteComment?: string;
}

interface DeletedMeasuresProps {
  onClose: () => void;
}

const DeletedMeasures: FC<DeletedMeasuresProps> = ({ onClose }) => {
  const dispatch = useDispatch();
  const siteId = useSiteId();
  const groupId = useGroupId();

  const localRegion = useSelector((state) => state.organization.localRegion);

  const { data: siteParameters } = useGetSiteParametersQuery({ siteId, groupId });

  const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);
  const [sortModel, setSortModel] = useState<CustomGridSortModel[]>([{ field: 'date', sort: 'desc' }]);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 100,
    page: 1
  });

  const { data: siteData, isFetching } = useGetMeasuresQuery(
    {
      siteId,
      groupId,
      pageIndex: paginationModel.page,
      pageSize: paginationModel.pageSize,
      onlyDeleted: true
    },
    {
      skip: !siteId || !groupId
    }
  );
  const measures = siteData?.measures;
  const rowCount = siteData?.totalCount || 0;
  const rows: GridRowsProp<RowModel> =
    measures?.map((measure) => {
      return {
        ...measure,

        id: measure.id,
        date: moment(measure.date).toDate(),
        sourceImportedAt: moment(measure.sourceImportedAt).toDate(),
        sourceConfirmedAt: moment(measure.sourceConfirmedAt).toDate(),
        deletedAt: moment(measure.deletedAt).toDate()
      };
    }) || [];

  const columns = useMemo(
    () =>
      [
        {
          field: 'date',
          headerName: t`Date`,
          headerAlign: 'left',
          width: 100,
          type: 'date',
          renderCell: (params) => <FormattedDate date={params.value} />
        },
        {
          field: 'measureImportFileName',
          headerName: t`File name`,
          headerAlign: 'left',
          width: 150
        },
        //this column is not used for the export file, but it is shown to the user
        {
          field: 'point',
          headerName: t`Point`,
          headerAlign: 'left',
          minWidth: 250,
          renderCell: (params) => (
            <NormalizationCell sourceValue={params.row.sourcePointName} normalizedValue={params.row.pointName} />
          ),
          valueGetter: (params) => {
            return params.row.sourcePointName;
          }
        },
        //this column is hidden for the user, but it is used for the export file
        {
          field: 'sourcePointName',
          headerName: t`Source point`
        },
        //this column is hidden for the user, but it is used for the export file
        {
          field: 'pointName',
          headerName: t`Normalized point`
        },
        //this column is not used for the export file
        {
          field: 'parameter',
          headerName: t`Parameter`,
          headerAlign: 'left',
          minWidth: 250,
          renderCell: (params) => (
            <NormalizationCell
              sourceValue={params.row.sourceParameterName}
              normalizedValue={params.row.parameterName}
            />
          ),
          valueGetter: (params) => {
            return params.row.sourceParameterName;
          }
        },
        //this column is hidden for the user, but it is used for the export file
        {
          field: 'sourceParameterName',
          headerName: t`Source parameter`
        },
        //this column is hidden for the user, but it is used for the export file
        {
          field: 'parameterName',
          headerName: t`Normalized parameter`
        },
        //this column is not used for the export file, but it is shown to the user
        {
          field: 'unit',
          headerName: t`Source unit`,
          headerAlign: 'left',
          minWidth: 250,
          renderCell: (params) => (
            <NormalizationCell sourceValue={params.row.sourceUnitName} normalizedValue={params.row.unitName} />
          ),
          valueGetter: (params) => {
            return params.row.sourceUnitName;
          }
        },
        //this column is hidden for the user, but it is used for the export file
        {
          field: 'sourceUnitName',
          headerName: t`Source unit`
        },
        //this column is hidden for the user, but it is used for the export file
        {
          field: 'unitName',
          headerName: t`Normalized unit`
        },
        //this column is not used for the export file, but it is shown to the user
        {
          field: 'unit-converter',
          headerName: t`Unit converter`,
          headerAlign: 'left',
          minWidth: 250,
          hideSortIcons: true,
          disableColumnMenu: true,
          renderCell: (params) => (
            <UnitConverterCell
              sourceValue={formatNumberUtils(params.row.sourceAmount, localRegion)}
              normalizedValue={formatNumberUtils(params.row.amount, localRegion)}
              sourceUnit={params.row.unitName}
              normalizedParameterId={params.row.parameterId}
              underDetectionLimit={params.row.underDetectionLimit}
            />
          )
        },
        //this column is hidden for the user, but it is used for the export file
        {
          field: 'sourceAmount',
          headerName: t`Source amount`,
          valueGetter: (params) => {
            return params.row.underDetectionLimit
              ? `< ${params.row.sourceAmount} ${params.row.unitName}`
              : `${params.row.sourceAmount} ${params.row.unitName}`;
          }
        },
        //this column is not used for the export file, but it is shown to the user
        {
          field: 'amount',
          headerName: t`Normalized amount`,
          valueGetter: (params) => {
            const parameter = siteParameters?.parameters.find((p) => p.id === params.row.parameterId);
            return params.row.underDetectionLimit
              ? `< ${params.row.amount} ${parameter?.units}`
              : `${params.row.amount} ${parameter?.units}`;
          }
        },
        {
          field: 'comments',
          headerName: t`Comments`,
          headerAlign: 'left',
          minWidth: 300,
          type: 'string'
        },
        {
          field: 'analysisId',
          headerName: t`Analyse Ref.`,
          headerAlign: 'left',
          width: 150
        },
        {
          field: 'sampleId',
          headerName: t`# Sample Ref.`,
          headerAlign: 'left',
          width: 150
        },
        {
          field: 'laboratoryName',
          headerName: t`Laboratory`,
          headerAlign: 'left',
          width: 150
        },
        {
          field: 'sourceImportedAt',
          headerName: t`Import date`,
          headerAlign: 'left',
          width: 150,
          type: 'date',
          renderCell: (params) => <FormattedDate date={params.value} />
        },
        {
          field: 'sourceConfirmedAt',
          headerName: t`Confirm date`,
          headerAlign: 'left',
          width: 150,
          type: 'date',
          renderCell: (params) => <FormattedDate date={params.value} />
        },
        {
          field: 'sourceConfirmedBy',
          headerName: t`Confirmed by`,
          headerAlign: 'left',
          width: 150
        },
        {
          field: 'deleteBy',
          headerName: t`Deleted by`,
          headerAlign: 'left',
          width: 150
        },
        {
          field: 'deletedAt',
          headerName: t`Delete date`,
          headerAlign: 'left',
          width: 150,
          type: 'date',
          renderCell: (params) => <FormattedDate date={params.value} />
        },
        {
          field: 'deleteComment',
          headerName: t`Delete comment`,
          headerAlign: 'left',
          width: 150
        }
      ] as GridColDef<RowModel>[],
    [siteParameters, localRegion]
  );

  const visibilityModel = {
    sourcePointName: false,
    pointName: false,
    sourceParameterName: false,
    parameterName: false,
    sourceUnitName: false,
    unitName: false,
    sourceAmount: false,
    amount: false
  };

  const hiddenFields = [
    'sourcePointName',
    'pointName',
    'sourceParameterName',
    'parameterName',
    'sourceUnitName',
    'unitName',
    'sourceAmount',
    'amount'
  ];

  const getTogglableColumns = (columns: GridColDef[]) => {
    return columns.filter((column) => !hiddenFields.includes(column.field)).map((column) => column.field);
  };

  const handleCellDoubleClick = (params: GridCellParams) => {
    onClose();

    switch (params.field) {
      case 'point':
        dispatch(setSelectedView('point'));
        dispatch(setSelectedPoint(params.row.pointName));
        break;

      case 'parameter':
        dispatch(setSelectedView('parameter'));
        dispatch(setSelectedParameter(params.row.parameterName));
        break;

      case 'date':
        dispatch(setSelectedView('date'));
        dispatch(
          setSelectedRange({
            startDate: moment(params.row.date).startOf('year').format(),
            endDate: moment(params.row.date).endOf('year').format(),
            range: 'year'
          })
        );
        break;

      default:
        break;
    }
  };

  const getExportableFieldColumns = () => {
    const exportableColumns = columns.filter((column) => visibilityModel[column.field] !== false);
    const exportableFieldColumns = exportableColumns.map((column) => column.field);

    // At this point, exportableFieldColumns is an array of strings like that:
    // ['date', 'measureImportFileName', 'point', 'parameter', 'unit', 'unit-converter', 'comments', 'analysisId', 'sampleId', 'laboratoryName', 'createdAt', 'confirmedBy']
    // but if 'point' is in the array, we need to replace it with 'sourcePointName' and 'pointName'
    // and if 'parameter' is in the array, we need to replace it with 'sourceParameterName' and 'parameterName'
    // and if 'unit' is in the array, we need to replace it with 'sourceUnitName' and 'unitName'
    // and if 'unit-converter' is in the array, we need to replace it with 'sourceAmount' and 'amount'

    if (exportableFieldColumns.includes('point')) {
      const pointIndex = exportableFieldColumns.indexOf('point');
      exportableFieldColumns.splice(pointIndex, 1, 'sourcePointName', 'pointName');
    }

    if (exportableFieldColumns.includes('parameter')) {
      const parameterIndex = exportableFieldColumns.indexOf('parameter');
      exportableFieldColumns.splice(parameterIndex, 1, 'sourceParameterName', 'parameterName');
    }

    if (exportableFieldColumns.includes('unit')) {
      const unitIndex = exportableFieldColumns.indexOf('unit');
      exportableFieldColumns.splice(unitIndex, 1, 'sourceUnitName', 'unitName');
    }

    if (exportableFieldColumns.includes('unit-converter')) {
      const unitConverterIndex = exportableFieldColumns.indexOf('unit-converter');
      exportableFieldColumns.splice(unitConverterIndex, 1, 'sourceAmount', 'amount');
    }

    return exportableFieldColumns;
  };

  const exportableColumns = getExportableFieldColumns();

  if (isFetching) return null;

  return (
    <>
      <DeletedMeasuresActions selectedRowIds={selectedRowIds} setSelectedRowIds={setSelectedRowIds} />
      <PersistentGrid
        slots={{
          toolbar: () => (
            <CustomTableToolbar fileTitle={t`Deleted-data`} hasExport={true} exportableColumns={exportableColumns} />
          ),
          columnMenu: CustomColumnMenu
        }}
        disableRowSelectionOnClick={true}
        onRowSelectionModelChange={(newRowSelectionModel: number[]) => {
          setSelectedRowIds(newRowSelectionModel);
        }}
        rowSelectionModel={selectedRowIds}
        rows={rows}
        rowHeight={70}
        columns={columns}
        pageSizeOptions={[100, 500, 1000]}
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model as CustomGridSortModel[])}
        onCellDoubleClick={handleCellDoubleClick}
        rowCount={rowCount}
        loading={isFetching}
        paginationModel={paginationModel}
        paginationMode="server"
        pagination
        onPaginationModelChange={setPaginationModel}
        slotProps={{
          columnsPanel: {
            getTogglableColumns
          }
        }}
        checkboxSelection={true}
        persistentKey="deleted-data"
        initialState={{
          columns: {
            columnVisibilityModel: visibilityModel
          }
        }}
      />
    </>
  );
};

export default DeletedMeasures;
