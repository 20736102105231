import { FC, useState } from 'react';
import toast from 'react-hot-toast';
import { t, Trans } from '@lingui/macro';
import useOrganizationId from 'src/hooks/useOrganizationId';
import { useAddMemberMutation } from 'src/slices/organization';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField
} from '@mui/material';
import useSiteId from 'src/hooks/useSiteId';
import OrganizationRoleSelect from 'src/components/selects/memberRoleSelect';
import { type OrganizationRoles as OrganizationRolesType, OrganizationRoles } from 'src/models/memberRoles';

function handleAddMemberError(error: any) {
  if (error) {
    switch (error?.data?.type) {
      case 'member-already-exists':
        toast.error(t`The member already exists.`);
        break;
      case 'entity-not-found':
        toast.error(
          t`The entity was not found in the database. Entity: ${error?.data?.entityName} with id: ${error?.data?.entityId}`
        );
        break;
      default:
        toast.error(error?.data?.detail || t`Something went wrong while adding the member.`);
        break;
    }
  } else {
    toast.error(t`Something went wrong.`);
  }
}

type AddMemberDialogProps = {
  onClose: () => void;
  onCancel: () => void;
  onComplete: () => void;
};

const AddMemberDialog: FC<AddMemberDialogProps> = ({ onCancel, onClose, onComplete }) => {
  const [email, setEmail] = useState<string>('');
  const [selectedRole, setSelectedRole] = useState<OrganizationRolesType>(OrganizationRoles.Member);
  const organizationId = useOrganizationId();
  const siteId = useSiteId();

  const [addMember] = useAddMemberMutation();

  const handleSave = async () => {
    try {
      await addMember({
        email: email,
        role: selectedRole,
        organizationId: organizationId,
        siteIds: [siteId]
      }).unwrap();
      toast.success(t`Member added successfully.`);
    } catch (error: any) {
      handleAddMemberError(error);
    }

    onComplete();
  };

  const handleRoleChange = (role: OrganizationRoles) => {
    setSelectedRole(role);
  };

  return (
    <Dialog onClose={onClose} open={true}>
      <DialogTitle>
        <Trans>Add a member</Trans>
      </DialogTitle>
      <DialogContent>
        <Grid container={true} direction="column" spacing={2}>
          <Grid item={true}>
            <DialogContentText>
              <Trans>Please complete the information below.</Trans>
            </DialogContentText>
          </Grid>
          <Grid item={true}>
            <TextField
              fullWidth={true}
              label={t`Email`}
              margin="dense"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              size="small"
              value={email}
            />
          </Grid>
          <Grid item={true}>
            <OrganizationRoleSelect value={selectedRole} inputLabel={t`Role`} onSelectionChanged={handleRoleChange} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>
          <Trans>Cancel</Trans>
        </Button>
        <Button onClick={() => handleSave()}>
          <Trans>Add</Trans>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddMemberDialog;
