import { useCallback } from 'react';
import { useSelector } from 'src/store';

const useUserLocalStorage = () => {
  const userId = useSelector<string>((state) => state.authentication?.user?.id);

  const setItem = useCallback(
    (key: string, value: string) => window.localStorage.setItem(`${userId}-${key}`, value),
    [userId]
  );
  const getItem = useCallback((key: string) => window.localStorage.getItem(`${userId}-${key}`), [userId]);

  return { setItem, getItem };
};

export default useUserLocalStorage;
