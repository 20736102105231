import useOrganizationId from 'src/hooks/useOrganizationId';
import useSiteId from 'src/hooks/useSiteId';
import { useEffect, useState } from 'react';
import { getOrganization } from 'src/services/organizations/getOrganization';
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton
} from '@mui/x-data-grid';
import LogoutIcon from '@mui/icons-material/Logout';
import { Stack } from '@mui/material';
import { useGetSiteQuery } from 'src/slices/organization';

interface CustomTableToolbarProps {
  fileTitle: string;
  hasExport?: boolean;
  exportableColumns?: string[];
}

const CustomTableToolbar = ({ fileTitle, hasExport = false, exportableColumns }: CustomTableToolbarProps) => {
  const organizationId = useOrganizationId();
  const siteId = useSiteId();
  const [organizationName, setOrganizationName] = useState<string>('');

  const { data: siteData } = useGetSiteQuery({ siteId });
  const site = siteData?.site;

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        const organizationResponse = await getOrganization({ id: organizationId });

        if (isMounted) {
          setOrganizationName(organizationResponse.organization.name);
        }
      } catch (error) {
        // Handle errors, if needed
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [organizationId]);

  return (
    <GridToolbarContainer style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
      <Stack direction="row">
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
      </Stack>
      <Stack direction="row">
        {hasExport && (
          <GridToolbarExport
            printOptions={{
              disableToolbarButton: true
            }}
            csvOptions={{
              fileName: `Krigis - ${organizationName} - ${site?.name} (${fileTitle})`,
              delimiter: ';',
              fields: exportableColumns ? exportableColumns : undefined
            }}
            startIcon={<LogoutIcon style={{ transform: 'rotate(-90deg)' }} />}
          />
        )}
      </Stack>
    </GridToolbarContainer>
  );
};

export default CustomTableToolbar;
