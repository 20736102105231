// Externals
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { toast } from 'react-hot-toast';
import { t } from '@lingui/macro';

const errorToast = () => {
  toast.error(t`You are not authorized to perform this action`);
};

const baseQueryWithToast = (baseUrl) => {
  const baseQuery = fetchBaseQuery({ baseUrl });
  return async (args, api, extraOptions) => {
    try {
      if (args) {
        const token = window.localStorage.getItem('token');
        const headers = {};
        if (token) {
          headers['Authorization'] = `Bearer ${token}`;
        }

        const result = await baseQuery({ ...args, headers }, api, extraOptions);
        if (result.error && result.error.status === 403) {
          errorToast();
          return { error: { status: result.error.status, data: result.error.data } };
        }

        if (result.error && result.error.status === 401) {
          console.error('An api call returned 401, clearing token..');
          window.localStorage.removeItem('token');
          window.location.reload();
        }
        return result;
      }
    } catch (error) {
      errorToast();
      return { error: { status: 'error', data: null } };
    }
  };
};

const api = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithToast(`${process.env.REACT_APP_API_URL}/api`),
  endpoints: (builder) => ({}),
  tagTypes: ['site-layers']
});

export default api;
