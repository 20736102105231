import { FC, PropsWithChildren, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'src/store';
import useSiteId from 'src/hooks/useSiteId';
import useOrganizationId from 'src/hooks/useOrganizationId';
import { initializeSiteConfig, useGetSiteConfigQuery, useUpdateSiteConfigMutation } from 'src/slices/site-config';
import { DashboardState, initializeDashboard } from 'src/slices/dashboard';
import { initializeTableNavigation, TableNavigationSate } from 'src/slices/table-navigation';
import { initializeAlertSettings, InitialSettingsState } from 'src/slices/alert-settings';
import { initializeLayers, LayersState } from 'src/slices/layers';
import { initializeKriging, KrigingState } from 'src/slices/kriging';
import { FlowState, initializeFlowConfig } from 'src/slices/flow';
import _ from 'lodash';

type UserSiteConfigurationState = {
  [key: string]: {
    dashboardConfig: DashboardState;
    layers: LayersState;
    krigingConfig: KrigingState;
    alertsConfig: InitialSettingsState;
    tableNavigationConfig: TableNavigationSate;
    flowConfig: FlowState;
  }
};

const SiteConfigProvider: FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useDispatch();
  const siteId = useSiteId();
  const organizationId = useOrganizationId();
  const key = `site-${siteId}`;

  // Reference to store the previous config state to compare
  const previousConfigRef = useRef<UserSiteConfigurationState | null>(null);

  // Fetch and update site config
  const { data, isFetching } = useGetSiteConfigQuery({ siteId }, { skip: !siteId || !previousConfigRef.current });
  const [updateSiteConfig] = useUpdateSiteConfigMutation();

  // Select configuration from Redux state
  const dashboardConfig = useSelector((state) => state.dashboard);
  const layers = useSelector((state) => state.layers);
  const krigingConfig = useSelector((state) => state.kriging);
  const alertsConfig = useSelector((state) => state.alertSettings);
  const tableNavigationConfig = useSelector((state) => state.tableNavigation);
  const flowConfig = useSelector((state) => state.flowConfig);

  // Initialize site configuration from backend (only once)
  useEffect(() => {
    if (!siteId || isFetching || !data || !data.configuration) return;
    try {
      const parsedConfig = JSON.parse(data.configuration)[key];
      dispatch(initializeSiteConfig(parsedConfig));
      dispatch(initializeDashboard(parsedConfig.dashboardConfig));
      dispatch(initializeKriging(parsedConfig.krigingConfig));
      dispatch(initializeAlertSettings(parsedConfig.alertsConfig));
      dispatch(initializeLayers(parsedConfig.layers));
      dispatch(initializeTableNavigation(parsedConfig.tableNavigationConfig));
      dispatch(initializeFlowConfig(parsedConfig.flowConfig));
    } catch (error) {
      console.error('Failed to parse site config from backend:', error);
    }
  }, [data, isFetching, dispatch]);

  // Save updated configuration to backend (throttle and optimized)
  useEffect(() => {
    if (isFetching || !siteId) return;

    const currentConfig = {
      [key]: {
        dashboardConfig,
        layers,
        krigingConfig,
        alertsConfig,
        tableNavigationConfig,
        flowConfig
      }
    };

    // Check if the current configuration is different from the previous configuration
    if (_.isEqual(previousConfigRef.current, currentConfig)) {
      return;
    }

    // Update the ref to the latest config
    previousConfigRef.current = currentConfig;

    // Throttle the saveConfig function to avoid saving too frequently
    const saveConfig = _.throttle(async (config: UserSiteConfigurationState) => {
      try {
        await updateSiteConfig({
          organizationId,
          siteId,
          configuration: JSON.stringify(config)
        });
      } catch (error) {
        console.error('Failed to save site config:', error);
      }
    }, 10000);

    saveConfig(currentConfig);

    // Cleanup debounce on unmount
    return () => {
      saveConfig.cancel();
    };
  }, [
    siteId,
    dashboardConfig,
    layers,
    krigingConfig,
    alertsConfig,
    tableNavigationConfig,
    flowConfig,
    updateSiteConfig,
    isFetching
  ]);

  return <>{children}</>;
};

export default SiteConfigProvider;