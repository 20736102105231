import { FC, PropsWithChildren, useEffect, useMemo } from 'react';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'src/store';
import { useGetMembersDataQuery } from 'src/slices/organization';
import useSiteId from 'src/hooks/useSiteId';
import useGroupId from 'src/hooks/useGroupId';
import useOrganizationId from 'src/hooks/useOrganizationId';
import SplashScreen from 'src/screens/SplashScreen';
import { useUpdateLastPath } from 'src/hooks/use-update-last-path';
import useUserLocalStorage from 'src/hooks/use-user-local-storage';

/**
 * Ensure that the user can access the organization site and group that are provided through the URL.
 * Otherwise, redirects to default route "/"
 * @param children the child component
 * @returns
 */
const RouteSiteGuard: FC<PropsWithChildren> = ({ children }) => {
  const userId = useSelector((state) => state.authentication.user?.id);
  const { getItem, setItem } = useUserLocalStorage();
  const organizationId = useOrganizationId();
  const siteId = useSiteId();
  const groupId = useGroupId();
  const { selectedGroupId } = useParams() as { selectedGroupId: string };
  const navigate = useNavigate();
  const location = useLocation();
  const { updateLastPath } = useUpdateLastPath();

  const { isFetching } = useGetMembersDataQuery({}, { refetchOnMountOrArgChange: true });
  const organizations = useSelector(
    (state) => state.organization && state.organization.organizations && state.organization.organizations.organizations
  );

  const foundOrganization = organizations?.find((organization) => organization.id === organizationId);
  const foundSite = foundOrganization?.sites.find((site) => site.id === siteId);
  const foundGroup = useMemo(() => {
    return groupId === '0' ? { id: '0' } : foundSite?.groups.find((g) => g.id === groupId);
  }, [foundSite?.groups, groupId]);

  useEffect(() => {
    if (foundGroup && foundSite && foundOrganization && userId) {
      updateLastPath(organizationId, siteId, groupId);
    }
  }, [foundOrganization, foundSite, foundGroup, userId, organizationId, siteId, groupId, updateLastPath]);

  useEffect(() => {
    if (selectedGroupId) {
      setItem(`${organizationId}-path`, 'groups');
      return;
    }

    let variablePath = '';
    if (organizationId) {
      variablePath += `/${organizationId}`;
      if (siteId) {
        variablePath += `/${siteId}`;
        if (groupId) {
          variablePath += `/${groupId}`;
        }
      }
    }

    if (variablePath) {
      // Remove the dynamic path portion from the URL and save the remaining path and search params
      const path = location.pathname.replace(variablePath, '').replace('/', '') + location.search;
      setItem(`${organizationId}-path`, path);
    }
  }, [groupId, location, organizationId, selectedGroupId, setItem, siteId, userId]);

  useEffect(() => {
    if (foundOrganization && location.pathname === '/') {
      const lastPage = getItem(`${organizationId}-path`) || 'map';
      const storedSiteId = getItem('site');
      const storedGroupId = getItem('group');

      if (storedSiteId && storedGroupId) {
        navigate(`/${organizationId}/${storedSiteId}/${storedGroupId}/${lastPage}`);
      } else {
        const defaultSiteId = foundOrganization?.sites[0]?.id;
        const defaultGroupId =
          defaultSiteId && foundOrganization.sites.find((site) => site.id === defaultSiteId)?.groups[0]?.id;
        if (defaultSiteId && defaultGroupId) {
          navigate(`/${organizationId}/${defaultSiteId}/${defaultGroupId}/${lastPage}`);
        }
      }
    }
  }, [foundOrganization, organizationId, navigate, location.pathname, userId, getItem]);

  if (isFetching) {
    return <SplashScreen />;
  }

  if ((groupId && !foundGroup) || (siteId && !foundSite) || (organizationId && !foundOrganization)) {
    return <Navigate replace={true} to="/" />;
  }

  return <>{children}</>;
};

export default RouteSiteGuard;
