import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'src/store';
import { formatDateUtils } from 'src/utils/format-date';

interface FormattedDateProps {
  date: Date | string;
}

export const FormattedDate: FC<FormattedDateProps> = ({ date }) => {
  const localRegion = useSelector((state) => state.organization.localRegion);

  const [localeLoaded, setLocaleLoaded] = useState(false);

  useEffect(() => {
    if (localRegion) {
      moment.locale(localRegion);
      setLocaleLoaded(true);
    }
  }, [localRegion]);

  if (!localeLoaded) return null;

  return <>{formatDateUtils(date)}</>;
};
