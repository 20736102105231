import { ElementType, FC, useEffect } from 'react';
import { AppBar, Grid, MenuItem, Select, Toolbar, useTheme } from '@mui/material';
import NavOptions from 'src/components/popovers/navOptions';
import Lang from 'src/lang';
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'src/store';
import useOrganizationId from 'src/hooks/useOrganizationId';
import useSiteId from 'src/hooks/useSiteId';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import useGroupId from 'src/hooks/useGroupId';
import { Trans } from '@lingui/macro';
import { useSiteRole } from 'src/hooks/use-role';
import { resetSelection } from 'src/slices/dashboard';
import { NAVBAR_HEIGHT } from 'src/constants/styles-constants';
import useUserLocalStorage from 'src/hooks/use-user-local-storage';
import { getFirstOrDefaultGroupId, getFirstSiteId } from 'src/utils/navigation';
import { useUpdateLastPath } from 'src/hooks/use-update-last-path';
import { SiteRoles } from 'src/models/memberRoles';

const Navbar: FC = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const organizationId = useOrganizationId();
  const siteId = useSiteId();
  const groupId = useGroupId();
  const { selectedGroupId } = useParams() as { selectedGroupId: string };
  const data = useSelector((state) => state.organization.organizations);
  const siteRole = useSiteRole();
  const userLocalStorage = useUserLocalStorage();
  const { updateLastPath } = useUpdateLastPath();

  useEffect(() => {
    if (siteRole === SiteRoles.Guest && groupId === '0') {
      navigate(`/${organizationId}/${siteId}/${getFirstOrDefaultGroupId(data, organizationId, siteId)}/map`);
    }
  }, [data, groupId, navigate, organizationId, siteId, siteRole]);

  const selectStyle = {
    color: theme.palette.primary.contrastText,
    fontSize: '1rem',
    maxHeight: NAVBAR_HEIGHT,
    border: 'none',
    '& .MuiSelect-icon': {
      color: theme.palette.primary.contrastText
    },

    boxShadow: 'none',
    borderRadius: 0,
    '.MuiOutlinedInput-notchedOutline': { border: 0 }
  };

  const getGroupSelect = (organizationId: number, siteId: number) => {
    const groups = data?.organizations
      ?.find((org) => org.id === organizationId)
      ?.sites.find((site) => site.id === siteId).groups;
    const isGroupEmpty = groups?.length === 0;

    const siteRole = data?.organizations
      .find((org) => org.id === organizationId)
      ?.sites?.find((site) => site.id === siteId).role;

    type selectProps = {
      readOnly: boolean;
      value: string | number;
      IconComponent: ElementType;
    };

    const isEmptyProps = {
      readOnly: true,
      value: '0',
      IconComponent: () => <></>
    };

    return (
      <>
        <ArrowForwardIosIcon
          fontSize="small"
          sx={{
            fontSize: '0.75rem'
          }}
        />

        <Select
          value={groupId}
          sx={selectStyle}
          onChange={(e) => onGroupChange(e.target.value as string)}
          {...((isGroupEmpty ? isEmptyProps : {}) as selectProps)}
        >
          {siteRole === SiteRoles.User && (
            <MenuItem value="0">
              <Trans>All Data</Trans>
            </MenuItem>
          )}
          {groups &&
            groups.length > 0 &&
            groups.map((group) => (
              <MenuItem key={group.id} value={group.id}>
                {group.name}
              </MenuItem>
            ))}
        </Select>
      </>
    );
  };

  const onOrganizationChange = (value: number | string) => {
    const newOrganizationId = value as number;

    const lastVisitedSiteId = Number(userLocalStorage.getItem(`org-${newOrganizationId}-last-site`));
    const lastVisitedGroupId = userLocalStorage.getItem(`site-${lastVisitedSiteId}-last-group`);

    const newSiteId = lastVisitedSiteId || getFirstSiteId(data, newOrganizationId);
    const newGroupId = lastVisitedGroupId || getFirstOrDefaultGroupId(data, newOrganizationId, newSiteId);

    updateLastPath(newOrganizationId, newSiteId, newGroupId);

    navigate(`/${newOrganizationId}/${newSiteId}/${newGroupId}/map`);
    dispatch(resetSelection());
  };

  const onSiteChange = (value: number | string) => {
    const newSiteId = value as number;
    const lastVisitedGroupIdFromLocalStorage = userLocalStorage.getItem(`site-${newSiteId}-last-group`);
    const lastVisitedGroupId =
      lastVisitedGroupIdFromLocalStorage || getFirstOrDefaultGroupId(data, organizationId, newSiteId);

    const lastPage = userLocalStorage.getItem(`${organizationId}-path`) || 'map';

    updateLastPath(organizationId, newSiteId, lastVisitedGroupId);

    // In the special case where we have a "selected group", we redirect to the groups page
    // It would not make sense to redirect to a group that is not in the selected site
    if (selectedGroupId) {
      navigate(`/${organizationId}/${newSiteId}/groups`);
    } else if (groupId) {
      // If we have a groupId in the URL, we want to keep it, otherwise we navigate to the last visited group
      navigate(`/${organizationId}/${newSiteId}/${lastVisitedGroupId}/${lastPage}`);
    } else {
      navigate(`/${organizationId}/${newSiteId}/${lastPage}`);
    }
    dispatch(resetSelection());
  };

  const onGroupChange = (value: number | string) => {
    const newGroupId = value as string;
    const lastPage = userLocalStorage.getItem(`${organizationId}-path`) || 'map';

    userLocalStorage.setItem(`group`, newGroupId);
    userLocalStorage.setItem(`site-${siteId}-last-group`, newGroupId);

    navigate(`/${organizationId}/${siteId}/${newGroupId}/${lastPage}`);
  };

  return (
    <AppBar
      elevation={0}
      sx={{
        justifyContent: 'center',
        maxHeight: NAVBAR_HEIGHT,
        backgroundColor: theme.palette.primary.main,
        boxShadow: 'none',
        color: theme.palette.primary.contrastText,
        zIndex: 100
      }}
    >
      <Toolbar style={{ paddingLeft: 8, paddingRight: 8 }}>
        <Grid
          container={true}
          direction="row"
          wrap="nowrap"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
        >
          <Grid item={true} container={true} direction="row" wrap="nowrap" alignItems="center" justifyContent="start">
            <img
              src="/assets/logo.png"
              alt="logo"
              style={{ height: 30, marginLeft: 10, cursor: 'pointer' }}
              onClick={() => navigate('/')}
            />
            <Grid item={true} container={true} direction="row" alignItems="center">
              {data?.organizations && (
                <>
                  <Select
                    value={organizationId}
                    sx={selectStyle}
                    onChange={(e) => onOrganizationChange(e.target.value)}
                  >
                    {data?.organizations?.map((organization) => (
                      <MenuItem key={organization.id} value={organization.id}>
                        {organization.name}
                      </MenuItem>
                    ))}
                  </Select>

                  {siteId !== null && data.organizations?.find((org) => org.id === organizationId)?.sites && (
                    <>
                      <ArrowForwardIosIcon
                        fontSize="small"
                        sx={{
                          fontSize: '0.75rem'
                        }}
                      />

                      <Select value={siteId} sx={selectStyle} onChange={(e) => onSiteChange(e.target.value)}>
                        {data.organizations
                          ?.find((org) => org.id === organizationId)
                          ?.sites?.map((site) => (
                            <MenuItem key={site.id} value={site.id}>
                              {site.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </>
                  )}
                  {groupId !== null && getGroupSelect(organizationId, siteId)}
                </>
              )}
            </Grid>
          </Grid>
          <Grid
            item={true}
            container={true}
            direction="row"
            justifyContent="end"
            alignItems="center"
            wrap="nowrap"
            spacing={2}
          >
            <Grid item={true}>
              <Lang />
            </Grid>
            <Grid item={true}>
              <NavOptions />
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
