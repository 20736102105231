import type { FC } from 'react';

// Externals
import { AppBar, Grid, Toolbar } from '@mui/material';

// Components
import NavOptions from 'src/components/popovers/navOptions';
import Lang from 'src/lang';
import { useNavigate } from 'react-router';
import { NAVBAR_HEIGHT } from 'src/constants/styles-constants';

const AdministrationNavbar: FC = () => {
  const navigate = useNavigate();

  return (
    <AppBar
      sx={{
        backgroundColor: (theme) => theme.palette.primary.main,
        boxShadow: 'none',
        color: (theme) => theme.palette.primary.contrastText,
        maxHeight: NAVBAR_HEIGHT,
        justifyContent: 'center'
      }}
      elevation={0}
    >
      <Toolbar>
        <img
          src="/assets/logo.png"
          alt="logo"
          style={{ height: 30, cursor: 'pointer' }}
          onClick={() => navigate('/')}
        />
        <Grid
          container={true}
          direction="row"
          wrap="nowrap"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
        >
          <Grid
            item={true}
            container={true}
            direction="row"
            justifyContent="end"
            alignItems="center"
            wrap="nowrap"
            spacing={2}
          >
            <Grid item={true}>
              <Lang />
            </Grid>
            <Grid item={true}>
              <NavOptions />
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default AdministrationNavbar;
