import { Table, TableBody, TableCell, TableRow, Typography, Link } from '@mui/material';
import { t } from '@lingui/macro';
import packageJson from '../../../../../package.json';

const About = () => {
  // TODO : Remplacer les valeurs hardcodées par des vraies valeurs
  type LicenseType = 'Full' | 'Basic';
  const version = packageJson.version; // Version
  const releaseDate = new Date().toLocaleDateString('fr-FR'); // Date de sortie
  const licenseType: LicenseType = 'Full'; // Type de licence
  const numberOfSeats = 5; // Nombre de sièges
  const currentYear = new Date().getFullYear(); // Année actuelle
  const disclaimerUrl = 'https://krigis.tech/disclaimer'; // URL du disclaimer

  const info = [
    { key: t`Name:`, value: 'Krigis' },
    { key: t`Version:`, value: version },
    // { key: t`Release date:`, value: releaseDate },
    // { key: t`License:`, value: `${licenseType} - ${numberOfSeats} seats` },
    {
      key: t`Website:`,
      value: (
        <Link href="https://krigis.tech" target="_blank" rel="noopener">
          https://krigis.tech
        </Link>
      )
    },
    { key: t`Support:`, value: <Link href="mailto:info@krigis.tech">info@krigis.tech</Link> },
    {
      key: t`Disclaimer:`,
      value: (
        <Link href={disclaimerUrl} target="_blank" rel="noopener">
          {disclaimerUrl}
        </Link>
      )
    },
    { key: t`Address:`, value: `Artesia - Bois Saint-Jean Street, 15/1 - 4120 Ougrée` },
    { key: t`Copyright:`, value: `© Krigis ${currentYear}` }
  ];

  return (
    <Table>
      <TableBody>
        {info.map((item, index) => (
          <TableRow
            key={index}
            sx={{
              '& > *:not(:last-child)': {
                borderRight: '1px solid rgba(0, 0, 0, 0.06)'
              }
            }}
          >
            <TableCell>
              <Typography variant="body1">{item.key}</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2">{item.value}</Typography>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default About;
