/* eslint-disable no-loop-func */
import { FC, useEffect } from 'react';
import { useRoutes } from 'react-router-dom';

// CSS
import './App.css';

// Externals
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { Toaster } from 'react-hot-toast';

// Routes
import routes from './routes';

// Screens
import SplashScreen from './screens/SplashScreen';

// Slices
import { initialize } from './slices/authentication';

// Stores
import { useDispatch, useSelector } from './store';

import { library } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { setLocalRegion } from './slices/organization';
import useRegionOptions from './hooks/useRegionOptions';

library.add(far);

const customTheme = createTheme({
  palette: {
    primary: {
      main: '#057FA8'
    }
  },
  components: {
    // @ts-ignore
    MuiDataGrid: {
      defaultProps: {
        localeText: {
          'filterOperator!=': '≠'
        },
      }
    },
    // @ts-ignore
    MuiDataGridPro: {
      defaultProps: {
        localeText: {
          'filterOperator!=': '≠'
        }
      }
    }
  }
});

const App: FC = () => {
  return (
    <ThemeProvider theme={customTheme}>
      <CssBaseline />
      <Toaster position="bottom-center" />
      <Content />
    </ThemeProvider>
  );
};

const Content: FC = () => {
  const dispatch = useDispatch();
  const content = useRoutes(routes);
  const REGION_OPTIONS = useRegionOptions();
  const userId = useSelector<string>((state) => state.authentication?.user?.id);
  const localRegion = localStorage.getItem(userId + '-localRegion') || 'fr-FR';

  const languagesToImport = new Set();

  REGION_OPTIONS.forEach((option) => {
    let language = option.value.split('-')[0];

    // Handle special cases for certain languages
    if (language === 'zh') {
      // Use specific locale codes for Chinese: zh-cn or zh-tw
      language = option.value === 'zh-CN' ? 'zh-cn' : 'zh-tw';
    }
    if (language !== 'en') {
      // Skip importing for 'en' as it is the default
      languagesToImport.add(language);
    }
  });

  for (const language of languagesToImport) {
    try {
      import(`moment/locale/${language}`);
    } catch (err) {
      console.error(`Failed to load locale ${language}:`, err);
    }
  }

  const isInitialized = useSelector<boolean>((state) => state.authentication.isInitialized);

  useEffect(() => {
    dispatch(initialize());
    userId && dispatch(setLocalRegion({ localRegion: localRegion, userId }));
  }, [dispatch, localRegion, userId]);

  if (!isInitialized) return <SplashScreen />;
  return content;
};

export default App;
