import { SiteRoles } from 'src/models/memberRoles';
import { GetMembersDataResponse } from 'src/slices/organization';

export const getFirstSiteId = (data: GetMembersDataResponse, organizationId: number) => {
  const site = data?.organizations.find((org) => org.id === organizationId).sites[0];
  return site.id;
};

export const getFirstOrDefaultGroupId = (data: GetMembersDataResponse, organizationId: number, siteId: number) => {
  const site = data.organizations.find((org) => org.id === organizationId).sites.find((site) => site.id === siteId);
  const siteRole = site.role;

  if (siteRole === SiteRoles.User) {
    return '0';
  }
  return site.groups[0].id;
};
