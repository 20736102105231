import { Trans } from '@lingui/macro';
import { LoadingButton } from '@mui/lab';
import { Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import useSiteId from 'src/hooks/useSiteId';
import { useComputePointParameterStatMutation } from 'src/slices/importdata';

const PointParameterStats = () => {
  const siteId = useSiteId();

  const [computePointParameterStat, { isLoading: computePointParameterStatIsLoading }] =
    useComputePointParameterStatMutation();

  const handleComputePointParameterStats = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    computePointParameterStat({ siteId });
  };

  return (
    <Table>
      <TableBody>
        <TableRow
          sx={{
            '& > *:not(:last-child)': {
              borderRight: '1px solid rgba(0, 0, 0, 0.06)'
            }
          }}
        >
          <TableCell>
            <Typography variant="body1" fontWeight="bold">
              <Trans>Point parameter stats</Trans>
            </Typography>
            <Typography variant="body2" color="textSecondary">
              <Trans>Compute point parameter for stats view on this site</Trans>
            </Typography>
          </TableCell>
          <TableCell>
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={handleComputePointParameterStats}
              loading={computePointParameterStatIsLoading}
              disabled={computePointParameterStatIsLoading}
            >
              <Trans>Compute</Trans>
            </LoadingButton>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default PointParameterStats;
