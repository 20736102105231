// UserRoles doit être utilisé pour savoir si l'utilisateur doit être redirigé vers la page d'administration ou la page principale utilisateur
export type UserRoles = (typeof UserRoles)[keyof typeof UserRoles];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserRoles = {
  SuperAdministrator: 'administrator',
  User: 'user'
} as const;

// OrganizationRoles permet de savoir si un user est un administrateur ou un membre d'une organisation
export type OrganizationRoles = (typeof OrganizationRoles)[keyof typeof OrganizationRoles];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrganizationRoles = {
  Administrator: 'administrator',
  Member: 'member'
} as const;

// SiteRoles permet de savoir si un membre est un guest ou un user
export type SiteRoles = (typeof SiteRoles)[keyof typeof SiteRoles];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SiteRoles = {
  Guest: 'guest',
  User: 'user'
} as const;
