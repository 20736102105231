import { FC } from 'react';
import { Box, Button, Divider, Skeleton, Typography } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { useDispatch } from 'src/store';
import { logout, useMeQuery } from 'src/slices/authentication';
import InitialsAvatar from 'src/components/avatars/initialsAvatar';
import { Trans } from 'react-i18next';

const NavOptionsContent: FC = () => {
  const dispatch = useDispatch();

  const { data: meData, isLoading: meIsLoading } = useMeQuery();

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', p: 2 }} gap={1}>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'nowrap', gap: 2 }}>
        {meIsLoading ? (
          <>
            <Skeleton variant="circular" width={40} height={40} />

            <Typography>
              <Skeleton variant="text" width={160} />
            </Typography>
          </>
        ) : (
          <>
            <InitialsAvatar email={meData.email.toUpperCase()} />
            <Typography>{meData.email}</Typography>
          </>
        )}
      </Box>

      <Divider />
      <Button
        onClick={handleLogout}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: 1,
          cursor: 'pointer',
          justifyContent: 'center'
        }}
        startIcon={<LogoutIcon color="primary" />}
      >
        <Trans>Log out</Trans>
      </Button>
    </Box>
  );
};

export default NavOptionsContent;
