import { FC } from 'react';
import { Navigate, Outlet } from 'react-router';
import { useOrganizationRole } from 'src/hooks/use-role';
import { type OrganizationRoles } from 'src/models/memberRoles';

interface OrganizationGuardDTO {
  role: OrganizationRoles;
}

const OrganizationGuard: FC<OrganizationGuardDTO> = ({ role }) => {
  const organizationRole = useOrganizationRole();

  if (organizationRole !== role) {
    return <Navigate to="/401" />;
  } else {
    return <Outlet />;
  }
};

export default OrganizationGuard;
