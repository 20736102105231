import { FC } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { t, Trans } from '@lingui/macro';
import toast from 'react-hot-toast';
import useOrganizationId from 'src/hooks/useOrganizationId';
import { useDeleteMemberMutation, OrganizationMemberDto } from 'src/slices/organization';

function handleDeleteMemberError(error) {
  if (error) {
    switch (error?.data?.type) {
      case 'entity-not-found':
        toast.error(
          t`The entity was not found in the database. Entity: ${error?.data?.entityName} with id: ${error?.data?.entityId}`
        );
        break;
      default:
        toast.error(error?.data?.detail || t`Something went wrong while deleting the member.`);
        break;
    }
  } else {
    toast.error(t`Something went wrong.`);
  }
}
interface DeleteMemberDialogProps {
  member: OrganizationMemberDto;
  isOpen: boolean;
  onClose: () => void;
  onCancel: () => void;
  onComplete: () => void;
}

const DeleteMemberDialog: FC<DeleteMemberDialogProps> = ({ isOpen, member, onCancel, onClose, onComplete }) => {
  const organizationId = useOrganizationId();

  const [removeMember] = useDeleteMemberMutation();

  const handleDelete = async () => {
    try {
      await removeMember({ organizationId, memberId: member.id }).unwrap();
      toast.success(t`Member deleted successfully.`);
    } catch (error) {
      handleDeleteMemberError(error);
    }
    onComplete();
  };

  return (
    <Dialog onClose={onClose} open={isOpen}>
      <DialogTitle>
        <Trans>Confirm deletion</Trans>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Trans>Are you sure you want to delete this member ?</Trans>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>
          <Trans>Cancel</Trans>
        </Button>
        <Button color="warning" onClick={handleDelete}>
          <Trans>Delete</Trans>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteMemberDialog;
