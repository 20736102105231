// API
import api from './api';

import type { PayloadAction } from '@reduxjs/toolkit';
// Externals
import { createSlice } from '@reduxjs/toolkit';
import jwt_decode from 'jwt-decode';

// Models
import { ClaimTypes } from 'src/models/claimTypes';
import { UserProfileDto } from 'src/models/userProfileDto';

// Slices
import { reset } from './organization';

// Store
import type { AppThunk } from 'src/store';
import { type UserRoles } from 'src/models/memberRoles';

//***************************************  STATE  ****************************************//

export type AuthenticationState = {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user?: UserProfileDto;
  role?: UserRoles;
};

const initialState: AuthenticationState = {
  isInitialized: false,
  isAuthenticated: false,
  user: undefined,
  role: undefined
};

//**********************************  REQUEST & RESULT  **********************************//

type MeResult = {
  id: string;
  email: string;
};

type LoginRequest = {
  email: string;
  password: string;
};

type LoginResult = {
  token: string;
  validUntil: Date;
};

type RegisterRequest = {
  email: string;
  password: string;
  invitationId: string;
};

//*****************************************  API  *****************************************//

export const extendedAuthenticationAPI = api.enhanceEndpoints({ addTagTypes: ['me'] }).injectEndpoints({
  endpoints: (builder) => ({
    me: builder.query<MeResult, void>({
      query: () => {
        return {
          url: '/auth/me',
          method: 'GET'
        };
      },
      providesTags: ['me']
    }),
    login: builder.mutation<LoginResult, LoginRequest>({
      query: (args) => ({
        url: '/auth/login',
        method: 'POST',
        body: args
      }),
      invalidatesTags: ['me']
    }),
    register: builder.mutation<void, RegisterRequest>({
      query: (args) => ({
        url: '/auth/register',
        method: 'POST',
        body: args
      })
    })
  })
});

//****************************************  SLICES  ****************************************//

const slice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    initialize(state: AuthenticationState, action: PayloadAction<{ isAuthenticated: boolean; role: UserRoles }>): void {
      state.isInitialized = true;
      state.isAuthenticated = action.payload.isAuthenticated;
      state.role = action.payload.role;
    },
    logout(state: AuthenticationState, action: PayloadAction<void>): void {
      state.isInitialized = true;
      state.isAuthenticated = false;
      state.user = undefined;
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(extendedAuthenticationAPI.endpoints.me.matchFulfilled, (state, { payload }) => {
      state.user = { id: payload.id, email: payload.email };
    });
    builder.addMatcher(extendedAuthenticationAPI.endpoints.me.matchRejected, (state, { payload }) => {
      state.user = undefined;
    });
    builder.addMatcher(extendedAuthenticationAPI.endpoints.login.matchFulfilled, (state, { payload }) => {
      state.isAuthenticated = true;

      const token = payload.token.toString();
      const validUntil = payload.validUntil.toString();

      const decoded = jwt_decode(token);
      state.role = decoded[ClaimTypes.Role];

      window.localStorage.setItem('token', token);
      window.localStorage.setItem('validUntil', validUntil);
    });
    builder.addMatcher(extendedAuthenticationAPI.endpoints.register.matchFulfilled, (state, { payload }) => {
      state.isAuthenticated = false;

      window.localStorage.removeItem('token');
      window.localStorage.removeItem('validUntil');
    });
  }
});

//****************************************  THUNKS  ****************************************//

export const initialize =
  (): AppThunk =>
  (dispatch): void => {
    const token: string = window.localStorage.getItem('token');
    const validUntil: Date = new Date(window.localStorage.getItem('validUntil'));

    let role = undefined;

    if (token) {
      const decoded = jwt_decode(token);
      role = decoded[ClaimTypes.Role];
    }

    dispatch(slice.actions.initialize({ isAuthenticated: token && validUntil > new Date(), role: role }));
  };

export const logout =
  (): AppThunk =>
  (dispatch): void => {
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('validUntil');

    dispatch(slice.actions.logout());

    dispatch(reset());
  };

//****************************************  EXPORT  ****************************************//

export const { reducer } = slice;

export const { useMeQuery, useLoginMutation, useRegisterMutation } = extendedAuthenticationAPI;
