import { Avatar } from '@mui/material';
import { FC, useEffect, useState } from 'react';

interface InitialsAvatarProps {
  initials?: string;
  email?: string;
  firstname?: string;
  lastname?: string;
}

const InitialsAvatar: FC<InitialsAvatarProps> = ({ initials: initialsProps, email, firstname, lastname }) => {
  const [initials, setInitials] = useState('u');
  const [color, setColor] = useState('blue');

  useEffect(() => {
    if (initials) {
      const color = getColor(initials);
      setColor(color);
    }
  }, [initials]);

  useEffect(() => {
    if (initialsProps) {
      setInitials(initialsProps);
    } else {
      const parts = getParts(email, firstname, lastname);
      const initials = getInitials(parts);
      setInitials(initials);
    }
  }, [email, firstname, initialsProps, lastname]);

  const getParts = (name: string, firstname: string, lastname: string) => {
    return firstname || lastname ? [firstname, lastname] : name ? name.split(' ') : [];
  };

  const getInitials = (parts: string[]) => {
    return parts
      .filter((p: string) => p && p.trim().length)
      .map((p: string) => p.trim()[0])
      .join('')
      .substring(0, 2);
  };

  const getColor = (initials: string) => {
    let hash = 0;
    let i: number;

    /* eslint-disable no-bitwise */
    for (i = 0; i < initials.length; i += 1) {
      hash = initials.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  };

  return <Avatar sx={{ bgcolor: color }}>{initials}</Avatar>;
};

export default InitialsAvatar;
