import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AggregationType } from './kriging';
import api from './api';

interface ILayerConfig {
  showAlerts: boolean;
  showPoints: boolean;
  showLabels: boolean;
  showKriging: boolean;
}

export type RangeType = 'week' | 'month' | 'quarter' | 'semester' | 'year' | 'custom';

export interface DateRange {
  startDate: string;
  endDate: string;
  range: RangeType;
}

export type MagnitudePrecision = 'full' | 'half' | 'third';

interface InitialSiteConfig {
  dashboardConfig: {
    siteId: number;
    selectedParameters: string[];
    selectedPoint: string;
    selectedPoints: string[];
    focusedPointData: { point: string; isGraphOpen: boolean };
    dateRange: DateRange;
    zoomLevel: number;
    mapPosition: { x: number; y: number };
  };
  layers: ILayerConfig;
  alertsConfig: {
    [key: string]: {
      siteId: number;
      tendancyAlertsValues: number[];
      zScoreAlertsValues: number[];
      isDifferentThanInitialState: boolean;
    };
  };
  krigingConfig: {
    scale: 'log' | 'linear';
    valueSelection: AggregationType;
    alpha: number;
    model: 'gaussian' | 'spherical' | 'exponential' | 'linear';
    variance: number;
    pixelSize: number;
    precision: MagnitudePrecision;
  };
  tableNavigationConfig: {
    selectedView: 'allData' | 'parameter' | 'point' | 'stat' | 'date';
    selectedAggregation: AggregationType;
    selectedDateGrouping: 'day' | 'week' | 'month' | 'year';
    selectedParameter: string;
    selectedPoint: string;
  };
  flowConfig: {
    color: string;
  };
}

const initialState: InitialSiteConfig = {
  dashboardConfig: {
    siteId: null,
    selectedParameters: [],
    selectedPoint: '',
    selectedPoints: [],
    focusedPointData: { point: '', isGraphOpen: false },
    dateRange: {
      startDate: '',
      endDate: '',
      range: 'year'
    },
    zoomLevel: 0,
    mapPosition: { x: 0, y: 0 }
  },
  layers: {
    showAlerts: false,
    showPoints: true,
    showLabels: true,
    showKriging: false
  },
  alertsConfig: {},
  krigingConfig: {
    scale: 'log',
    valueSelection: 'last',
    model: 'linear',
    variance: 0,
    alpha: 100,
    pixelSize: 10,
    precision: 'third'
  },
  tableNavigationConfig: {
    selectedView: 'stat',
    selectedAggregation: 'avg',
    selectedDateGrouping: 'day',
    selectedParameter: '',
    selectedPoint: ''
  },
  flowConfig: {
    color: '#000000'
  }
};

const siteConfig = createSlice({
  name: 'site-config',
  initialState,
  reducers: {
    initializeSiteConfig: (state, action: PayloadAction<InitialSiteConfig>) => {
      state.dashboardConfig = action.payload.dashboardConfig;
      state.alertsConfig = action.payload.alertsConfig;
      state.layers = action.payload.layers;
      state.krigingConfig = action.payload.krigingConfig;
      state.tableNavigationConfig = action.payload.tableNavigationConfig;
      state.flowConfig = action.payload.flowConfig;
    }
  }
});

type GetSiteConfigRequest = {
  siteId: number;
}

type GetSiteConfigResponse = {
  configuration: string;
}

type UpdateSiteConfigRequest = {
  organizationId: number;
  siteId: number;
  configuration: string;
}

const siteApi = api
  .enhanceEndpoints({ addTagTypes: ['user-site-configuration'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getSiteConfig: builder.query<GetSiteConfigResponse, GetSiteConfigRequest>({
        query: (params) => ({
          url: `members/mine/sites/${params.siteId}/configuration`
        }),
        providesTags: ['user-site-configuration']
      }),
      updateSiteConfig: builder.mutation<GetSiteConfigResponse, UpdateSiteConfigRequest>({
        query: (params) => ({
          url: `members/mine/organizations/${params.organizationId}/sites/${params.siteId}/configuration`,
          method: 'PUT',
          body: { configuration: params.configuration }
        }),
        invalidatesTags: ['user-site-configuration']
      })
    })
  });

export const { initializeSiteConfig } = siteConfig.actions;
export const { reducer } = siteConfig;
export const { useGetSiteConfigQuery, useUpdateSiteConfigMutation } = siteApi;