import type { FC, ReactNode } from 'react';

// Externals
import type { ListItemProps } from '@mui/material';
import { IconButton, ListItem } from '@mui/material';
import { NavLink as RouterLink } from 'react-router-dom';

interface ItemProps extends ListItemProps {
  active?: boolean;
  icon?: ReactNode;
  info?: ReactNode;
  path?: string;
  title: string;
  onClick?: () => void;
}

const Item: FC<ItemProps> = (props) => {
  const { active, icon, path, title, onClick } = props;

  // Leaf
  return (
    <ListItem
      disableGutters
      sx={{
        display: 'flex',
        py: 0
      }}
    >
      <IconButton
        component={path && RouterLink}
        sx={{
          color: 'text.secondary',
          fontWeight: 'fontWeightMedium',
          justifyContent: 'center',
          textAlign: 'center',
          p: '12px',
          textTransform: 'none',
          width: '100%',
          ...(active && {
            color: 'primary.main',
            fontWeight: 'fontWeightBold',
            '& svg': {
              color: 'primary.main'
            }
          })
        }}
        to={path}
        title={title}
        onClick={() => onClick && onClick()}
      >
        {icon}
      </IconButton>
    </ListItem>
  );
};

export default Item;
