// Externals
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

// Models
import api from './api';
import moment from 'moment';
import { AdminOrganizationDto } from '../services/organizations/getOrganization/models';
import { type OrganizationRoles, type SiteRoles } from 'src/models/memberRoles';

//***************************************  STATE  ****************************************//

export type OrganizationState = {
  organizations: GetMembersDataResponse;
  localRegion: string;
};

const initialState: OrganizationState = {
  organizations: null,
  localRegion: 'fr-FR'
};

//*****************************************  REDUCERS  *****************************************//

const slice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    setLocalRegion(state: OrganizationState, action: PayloadAction<{ localRegion: string; userId: string }>): void {
      const { localRegion, userId } = action.payload;

      try {
        moment.locale(localRegion);
      } catch (e) {
        console.error(`Failed to load locale ${localRegion}:`, e);
      }

      state.localRegion = localRegion;
      window.localStorage.setItem(`${userId}-localRegion`, localRegion);
    },
    reset: () => initialState
  },
  extraReducers: (builder) => {
    builder.addMatcher(extendedOrganizationAPI.endpoints.getMembersData.matchFulfilled, (state, { payload }) => {
      state.organizations = payload;
    });
  }
});

//**********************************  REQUEST & RESULT  **********************************//

export type OrganizationMemberDto = {
  id: number;
  firstname: string;
  lastname: string;
  email: string;
  role: OrganizationRoles;
  userProfileId: number;
  organizationId: number;
  sites: { id: number; name: string }[];
  organizationName: string;
};

export type MembersGroupDto = {
  id: string;
  name: string;
};

type MembersSitesDto = {
  id: number;
  name: string;
  groups: MembersGroupDto[];
  role: SiteRoles;
};

type MembersOrganizationsDto = {
  id: number;
  name: string;
  role: OrganizationRoles;
  sites: MembersSitesDto[];
};

export type GetMembersDataResponse = {
  organizations: MembersOrganizationsDto[];
};

type GetMembersDataRequest = {};

type GetMembersResponse = {
  members: OrganizationMemberDto[];
};

type GetMembersRequest = {
  organizationId: number;
};

type AddMemberRequest = {
  organizationId: number;
  email: string;
  role: OrganizationRoles;
  siteIds: number[];
};

type UpdateMemberRequest = {
  organizationId: number;
  memberId: number;
  role: OrganizationRoles;
  siteIds: number[];
};

type DeleteMemberRequest = {
  organizationId: number;
  memberId: number;
};

export type SiteDto = {
  id: number;
  organizationId: number;
  name: string;
  computeStatFrom: Date;
  longitude?: number;
  latitude?: number;
  x?: number;
  y?: number;
  mapCenterX?: number;
  mapCenterY?: number;
  mapRadius?: number;
};

type GetSitesResponse = {
  sites: SiteDto[];
};

type GetSitesRequest = {
  organizationId: number;
};

type GetSiteResponse = {
  site: SiteDto;
};

type GetSiteRequest = {
  siteId: number;
};

type AddSiteRequest = {
  organizationId: number;
  siteName: string;
};

type AddSiteResponse = {
  id: number;
  name: string;
};

type PatchSiteRequest = {
  siteId: number;
  name?: string;
  longitude?: number;
  latitude?: number;
  x?: number;
  y?: number;
  mapCenterX?: number;
  mapCenterY?: number;
  mapRadius?: number;
};

type DeleteSiteRequest = {
  siteId: number;
};

type GetOrganizationsResponse = {
  organizations: AdminOrganizationDto[];
};

type GetOrganizationsRequest = {};

type AddOrganizationRequest = {
  organization: AdminOrganizationDto;
};

type UpdateOrganizationRequest = {
  organization: AdminOrganizationDto;
};

type GetOrganizationRequest = {
  organizationId: number;
};

type GetOrganizationResponse = {
  organization: AdminOrganizationDto;
};

//*****************************************  API  *****************************************//

export const extendedOrganizationAPI = api
  .enhanceEndpoints({
    addTagTypes: [
      'members-data-me',
      'organization',
      'organization-members',
      'organization-sites',
      'site',
      'site-members'
    ]
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getMembersData: builder.query<GetMembersDataResponse, GetMembersDataRequest>({
        query: () => ({ url: '/members/mine', method: 'GET' }),
        providesTags: ['members-data-me']
      }),
      getMembers: builder.query<GetMembersResponse, GetMembersRequest>({
        query: (args) => ({ url: `/organizations/${args.organizationId}/members`, method: 'GET' }),
        providesTags: ['organization-members', 'site-members']
      }),
      addMember: builder.mutation<void, AddMemberRequest>({
        query: (args) => ({
          url: `/organizations/${args.organizationId}/members`,
          method: 'POST',
          body: { email: args.email, role: args.role, siteIds: args.siteIds }
        }),
        invalidatesTags: ['organization-members']
      }),
      updateMember: builder.mutation<void, UpdateMemberRequest>({
        query: (args) => ({
          url: `/organizations/${args.organizationId}/members`,
          method: 'PUT',
          body: { memberId: args.memberId, role: args.role, siteIds: args.siteIds }
        }),
        invalidatesTags: ['organization-members']
      }),
      deleteMember: builder.mutation<void, DeleteMemberRequest>({
        query: (args) => ({
          url: `/organizations/${args.organizationId}/members`,
          method: 'DELETE',
          body: { memberId: args.memberId }
        }),
        invalidatesTags: ['organization-members']
      }),
      getSites: builder.query<GetSitesResponse, GetSitesRequest>({
        query: (args) => ({ url: `/organizations/${args.organizationId}/sites`, method: 'GET' }),
        providesTags: ['organization-sites']
      }),
      getSite: builder.query<GetSiteResponse, GetSiteRequest>({
        query: (args) => ({ url: `/sites/${args.siteId}`, method: 'GET' }),
        providesTags: ['site']
      }),
      addSite: builder.mutation<AddSiteResponse, AddSiteRequest>({
        query: (args) => ({
          url: `/organizations/${args.organizationId}/sites`,
          method: 'POST',
          body: { siteName: args.siteName }
        }),
        invalidatesTags: ['organization-sites']
      }),
      patchSite: builder.mutation<void, PatchSiteRequest>({
        query: (args) => ({
          url: `/sites/${args.siteId}`,
          method: 'PUT',
          body: args
        }),
        invalidatesTags: ['site']
      }),
      deleteSite: builder.mutation<void, DeleteSiteRequest>({
        query: (args) => ({
          url: `/sites/${args.siteId}`,
          method: 'DELETE'
        }),
        invalidatesTags: ['organization-sites']
      }),
      getOrganization: builder.query<GetOrganizationResponse, GetOrganizationRequest>({
        query: (args) => ({ url: `/organizations/${args.organizationId}`, method: 'GET' }),
        providesTags: ['organization']
      }),
      getOrganizations: builder.query<GetOrganizationsResponse, GetOrganizationsRequest>({
        query: () => ({ url: '/admin/organizations', method: 'GET' }),
        providesTags: ['organization']
      }),
      addOrganization: builder.mutation<void, AddOrganizationRequest>({
        query: (args) => ({
          url: '/organizations',
          method: 'POST',
          body: args.organization
        }),
        invalidatesTags: ['organization']
      }),
      updateOrganization: builder.mutation<void, UpdateOrganizationRequest>({
        query: (args) => ({
          url: `/organizations`,
          method: 'PUT',
          body: args.organization
        }),
        invalidatesTags: ['organization']
      })
    })
  });

// Export slice and actions
export const { reducer } = slice;
export const { reset, setLocalRegion } = slice.actions;

export const {
  useGetMembersDataQuery,
  useGetMembersQuery,
  useAddMemberMutation,
  useUpdateMemberMutation,
  useDeleteMemberMutation,
  useGetSitesQuery,
  useGetSiteQuery,
  useAddSiteMutation,
  usePatchSiteMutation,
  useDeleteSiteMutation,
  useGetOrganizationQuery,
  useGetOrganizationsQuery,
  useAddOrganizationMutation,
  useUpdateOrganizationMutation
} = extendedOrganizationAPI;

export const invalidateTags = extendedOrganizationAPI.util.invalidateTags;
