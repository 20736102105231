import { ElementType, type FC, useEffect, useState } from 'react';
import {
  AppBar,
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Skeleton,
  Toolbar,
  useTheme
} from '@mui/material';
import { matchPath, useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'src/store';
import useOrganizationId from 'src/hooks/useOrganizationId';
import useSiteId from 'src/hooks/useSiteId';
import useGroupId from 'src/hooks/useGroupId';
import { t, Trans } from '@lingui/macro';
import { useSiteRole } from 'src/hooks/use-role';
import { resetSelection } from 'src/slices/dashboard';
import { NAVBAR_HEIGHT } from 'src/constants/styles-constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Stack } from '@mui/system';
import { Link } from 'react-router-dom';
import { logout, useMeQuery } from 'src/slices/authentication';
import useUserLocalStorage from 'src/hooks/use-user-local-storage';
import { getFirstOrDefaultGroupId, getFirstSiteId } from 'src/utils/navigation';
import { SiteRoles } from 'src/models/memberRoles';

type useLinksType = (
  organizationId: number,
  siteId: number,
  groupId: string
) => {
  title: string;
  path: string;
  icon: React.JSX.Element;
}[];

const useLinks: useLinksType = (organizationId, siteId, groupId) => [
  {
    title: t`Map`,
    path: `/${organizationId}/${siteId}/${groupId}/map`,
    icon: <FontAwesomeIcon size="xl" icon={['far', 'map-location-dot']} />
  },
  {
    title: t`Measurements`,
    path: `/${organizationId}/${siteId}/${groupId}/table`,
    icon: <FontAwesomeIcon size="xl" icon={['far', 'table-cells']} />
  }
];

const MobileNavbar: FC = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const organizationId = useOrganizationId();
  const siteId = useSiteId();
  const groupId = useGroupId();
  const { getItem } = useUserLocalStorage();
  const { selectedGroupId } = useParams() as { selectedGroupId: string };
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const data = useSelector((state) => state.organization.organizations);
  const siteRole = useSiteRole();
  const { data: meData } = useMeQuery();

  useEffect(() => {
    if (siteRole === SiteRoles.Guest && groupId === '0') {
      navigate(`/${organizationId}/${siteId}/${getFirstOrDefaultGroupId(data, organizationId, siteId)}/map`);
    }
  }, [data, groupId, navigate, organizationId, siteId, siteRole]);

  const getGroupSelect = (organizationId: number, siteId: number) => {
    const groups = data?.organizations
      ?.find((org) => org.id === organizationId)
      ?.sites.find((site) => site.id === siteId).groups;
    const isGroupEmpty = groups?.length === 0;

    const siteRole = data?.organizations
      .find((org) => org.id === organizationId)
      ?.sites?.find((site) => site.id === siteId).role;

    type selectProps = {
      readOnly: boolean;
      value: string | number;
      IconComponent: ElementType;
    };

    const isEmptyProps = {
      readOnly: true,
      value: '0',
      IconComponent: () => <></>
    };

    return (
      <Select
        size="small"
        value={groupId}
        onChange={(e) => onGroupChange(e.target.value as string)}
        {...((isGroupEmpty ? isEmptyProps : {}) as selectProps)}
      >
        {siteRole === SiteRoles.User && (
          <MenuItem value="0">
            <Trans>All Data</Trans>
          </MenuItem>
        )}
        {groups &&
          groups.length > 0 &&
          groups.map((group) => (
            <MenuItem key={group.id} value={group.id}>
              {group.name}
            </MenuItem>
          ))}
      </Select>
    );
  };

  const onOrganizationChange = (value: number | string) => {
    // invalidate siteId and groupId
    const newOrganizationId = value as number;
    const newSiteId = getFirstSiteId(data, newOrganizationId);
    const newGroupId = getFirstOrDefaultGroupId(data, newOrganizationId, newSiteId);
    navigate(`/${newOrganizationId}/${newSiteId}/${newGroupId}/map`);
    dispatch(resetSelection());
  };

  const onSiteChange = (value: number | string) => {
    // invalidate groupId
    const newSiteId = value as number;
    const firstGroupId = getFirstOrDefaultGroupId(data, organizationId, newSiteId);
    const lastPage = getItem(`${organizationId}-path`) || 'map';

    // In the special case where we have a "selected group", we redirect to the groups page
    // It would not make sense to redirect to a group that is not in the selected site
    if (selectedGroupId) {
      navigate(`/${organizationId}/${newSiteId}/groups`);
    } else if (groupId) {
      // If we have a groupId in the URL, we want to keep it, otherwise we navigate to the last visited group
      navigate(`/${organizationId}/${newSiteId}/${firstGroupId}/${lastPage}`);
    } else {
      navigate(`/${organizationId}/${newSiteId}/${lastPage}`);
    }

    dispatch(resetSelection());
  };

  const onGroupChange = (value: number | string) => {
    const lastPage = getItem(`${organizationId}-path`) || 'map';
    navigate(`/${organizationId}/${siteId}/${value}/${lastPage}`);
  };

  const toggleMenu = () => {
    setMenuIsOpen((prev) => !prev);
  };

  const onMenuClose = () => setMenuIsOpen(false);

  const isActive = (path: string) => {
    return matchPath({ path, end: true }, window.location.pathname);
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <>
      <AppBar
        elevation={0}
        sx={{
          justifyContent: 'center',
          height: NAVBAR_HEIGHT,
          backgroundColor: theme.palette.primary.main,
          boxShadow: 'none',
          color: theme.palette.primary.contrastText,
          zIndex: 10000 // 10000 is used to be above the drawer
        }}
      >
        <Toolbar
          style={{
            paddingLeft: 8,
            paddingRight: 8,
            display: 'fex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          <img
            src="/assets/logo.png"
            alt="logo"
            style={{ height: 30, cursor: 'pointer' }}
            onClick={() => navigate('/')}
          />
          <IconButton>
            <FontAwesomeIcon color="white" icon={['far', menuIsOpen ? 'xmark' : 'bars']} onClick={toggleMenu} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="top"
        open={menuIsOpen}
        onClose={onMenuClose}
        ModalProps={{
          keepMounted: true,
          sx: {
            top: NAVBAR_HEIGHT
          }
        }}
        PaperProps={{
          sx: {
            marginTop: NAVBAR_HEIGHT
          }
        }}
      >
        <Box sx={{ width: 'auto' }} role="presentation">
          <List>
            <ListItem>
              <Stack sx={{ display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center' }}>
                <ListItemText primary={t`Organization :`} />
                <Select size="small" value={organizationId} onChange={(e) => onOrganizationChange(e.target.value)}>
                  {data?.organizations?.map((organization) => (
                    <MenuItem key={organization.id} value={organization.id}>
                      {organization.name}
                    </MenuItem>
                  ))}
                </Select>
              </Stack>
            </ListItem>

            <ListItem>
              <Stack sx={{ display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center' }}>
                <ListItemText primary={t`Site :`} />
                {siteId !== null && data.organizations?.find((org) => org.id === organizationId)?.sites && (
                  <Select size="small" value={siteId} onChange={(e) => onSiteChange(e.target.value)}>
                    {data.organizations
                      ?.find((org) => org.id === organizationId)
                      ?.sites?.map((site) => (
                        <MenuItem key={site.id} value={site.id}>
                          {site.name}
                        </MenuItem>
                      ))}
                  </Select>
                )}
              </Stack>
            </ListItem>
            {groupId !== null && (
              <ListItem>
                <Stack sx={{ display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center' }}>
                  <ListItemText primary={t`Group :`} />
                  {getGroupSelect(organizationId, siteId)}
                </Stack>
              </ListItem>
            )}
          </List>
          <Divider />

          {useLinks(organizationId, siteId, groupId).map((link) => (
            <ListItem key={link.title} disablePadding>
              <ListItemButton component={Link} to={link.path} onClick={onMenuClose}>
                <ListItemIcon sx={{ color: (theme) => (isActive(link.path) ? theme.palette.primary.main : undefined) }}>
                  {link.icon}
                </ListItemIcon>
                <ListItemText
                  sx={{ color: (theme) => (isActive(link.path) ? theme.palette.primary.main : undefined) }}
                  primary={link.title}
                />
              </ListItemButton>
            </ListItem>
          ))}

          <Divider />
          <ListItem disablePadding>
            <ListItemButton onClick={handleLogout}>
              <ListItemIcon>
                <FontAwesomeIcon size="xl" icon={['far', 'right-from-bracket']} />
              </ListItemIcon>
              <ListItemText primary={meData ? meData.email : <Skeleton variant="text" width={160} />} />
            </ListItemButton>
          </ListItem>
        </Box>
      </Drawer>
    </>
  );
};

export default MobileNavbar;
