import type { FC } from 'react';

// Components
import AdministrationNavbar from './navbar';
import AdministrationSidebar from './sidebar';

// Externals
import { experimentalStyled } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { NAVBAR_HEIGHT } from 'src/constants/styles-constants';

const AdministrationLayoutRoot = experimentalStyled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
  width: '100%'
}));

const AdministrationLayoutWrapper = experimentalStyled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  paddingTop: NAVBAR_HEIGHT,
  paddingLeft: '280px'
}));

const AdministrationLayoutContainer = experimentalStyled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden'
});

const AdministrationLayoutContent = experimentalStyled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto',
  position: 'relative',
  WebkitOverflowScrolling: 'touch'
});

const AdministrationLayout: FC = () => {
  return (
    <AdministrationLayoutRoot>
      <AdministrationNavbar />
      <AdministrationSidebar />
      <AdministrationLayoutWrapper>
        <AdministrationLayoutContainer>
          <AdministrationLayoutContent>
            <Outlet />
          </AdministrationLayoutContent>
        </AdministrationLayoutContainer>
      </AdministrationLayoutWrapper>
    </AdministrationLayoutRoot>
  );
};

export default AdministrationLayout;
