import api from './api';

// Externals
import { createSlice } from '@reduxjs/toolkit';

//Types
import type {
  NormalizeTextRule,
  Parameter,
  Point,
  PointComment,
  PointMedia,
  PointParameterStat,
  UnconfirmedMeasureSource,
  UnitConverter
} from 'src/types/site-data.model';
import { GridRowSelectionModel } from '@mui/x-data-grid';

//***************************************  STATE  ****************************************//

interface siteDataState {}

const initialState: siteDataState = {};

//**********************************  REQUEST & RESULT  **********************************//

interface GetSiteUnconfirmedMeasureSourcesDataResult {
  unconfirmedMeasureSources: UnconfirmedMeasureSource[];
}

interface GetSiteUnconfirmedMeasureSourcesDataRequest {
  siteId: number;
}

interface GetImportedFileNamesResult {
  fileNames: string[];
}

interface GetImportedFileNamesRequest {
  siteId: number;
}

interface ImportMeasureSourcesRequest {
  siteId: number;
  formData: FormData;
}

interface GetSitePointRequest {
  siteId: number;
  pointId: string;
  groupId?: string;
}

interface GetSitePointResult {
  point: Point;
}

interface GetSitePointsRequest {
  siteId: number;
  groupId?: string;
}

interface GetSitePointsResult {
  points: Point[];
}

interface GetSiteParameterRequest {
  siteId: number;
  parameterId: string;
  groupId?: string;
}

interface GetSiteParameterResult {
  parameter: Parameter;
}

interface GetSiteParametersRequest {
  siteId: number;
  groupId?: string;
}

interface GetSiteParametersResult {
  parameters: Parameter[];
}

interface GetSiteUnitsRequest {
  siteId: number;
}

interface GetSiteUnitsResult {
  units: {
    id: number;
    name: string;
  }[];
}

interface GetSitePointParameterStatsRequest {
  siteId: number;
  groupId: string;
}

interface GetSitePointParameterStatsResult {
  pointParameterStats: PointParameterStat[];
}

interface GetSiteUnitConvertersRequest {
  siteId: number;
}

interface GetSiteUnitConvertersResult {
  unitConverters: UnitConverter[];
}

interface AddSitePointRequest {
  name: string;
  x: number;
  y: number;
  z: number;
  depth: number;
  maxDepth1: number;
  maxDepth2: number;
  maxDepth3: number;
  maxDepth4: number;
  hasSamplePoint: boolean;
  aquifer: string;
  type: string;
  siteId: number;
  comment: string;
  author: string;
}

interface AddSitePointResult {}

interface DeleteSitePointRequest {
  siteId: number;
  pointId: string;
}

interface DeleteSitePointResult {}

interface UploadPointMediaRequest {
  siteId: number;
  pointId: string;
  formData: FormData;
}

interface GetPointMediasRequest {
  siteId: number;
  groupId: string;
  pointId: string;
}

interface GetPointMediasResult {
  medias: PointMedia[];
}

interface UpdatePointMediaCommentRequest {
  siteId: number;
  pointId: string;
  mediaId: string;
  comment: string;
}

interface AddPointCommentRequest {
  siteId: number;
  pointId: string;
  author: string;
  comment: string;
}

interface GetPointCommentsRequest {
  siteId: number;
  groupId: string;
  pointId: string;
}

interface GetPointCommentsResult {
  pointComments: PointComment[];
}

interface AddSiteParameterRequest {
  name: string;
  type: string;
  norm: number;
  normDefinition: string;
  units: string;
  maxValue: number;
  minValue: number;
  siteId: number;
  comment: string;
}

interface AddSiteParameteResult {}

interface DeleteSiteParameterRequest {
  siteId: number;
  parameterId: string;
}

interface DeleteSiteParameterResult {}

interface AddSiteUnitRequest {
  siteId: number;
  name: string;
}

interface AddSiteUnitResult {
  id: number;
}

interface AddRuleRequest {
  oldValue: string;
  newValue: string;
  siteId: number;
}

interface AddRuleResult {}

interface UpdateRuleRequest {
  normalizationId: number;
  newValue: string;
  siteId: number;
}

interface UpdateRuleResult {}

interface AddUnitConverterRequest {
  sourceUnit: string;
  targetUnit: string;
  factor: number;
  siteId: number;
}

interface AddUnitConverterResult {
  id: number;
}

interface UpdatePointNameRequest {
  siteId: number;
  measureSourceId: number;
  pointId: string;
}

interface UpdateParameterNameRequest {
  siteId: number;
  measureSourceId: number;
  parameterId: string;
}

interface UpdateUnitNameRequest {
  siteId: number;
  measureSourceId: number;
  unitId: number;
}

interface UpdateMeasureSourceStatusRequest {
  siteId: number;
  measureSourceIds: number[];
  status: string;
  verificationStatusChangedBy: string;
}

interface NormalizeRequest {
  siteId: number;
  normalizeParameters?: boolean;
  normalizePoints?: boolean;
  normalizeUnits?: boolean;
}

interface AddConfirmedMeasuresResult {}

interface AddConfirmedMeasuresRequest {
  siteId: number;
  measureSourceIds: number[];
  confirmBy: string;
}

interface RemoveMeasureSourcesRequest {
  siteId: number;
  measureSourceIds: number[];
}

interface RemoveMeasuresRequest {
  siteId: number;
  measuresToDelete: MeasureToDelete[];
  isHardDelete: boolean;
}

interface RestoreMeasuresRequest {
  siteId: number;
  measureIdsToRestore: number[];
}

interface MeasureToDelete {
  measureId: number;
  comment?: string;
}

interface GetNormailizeTextRulesResult {
  normalizeTextRules: NormalizeTextRule[];
}

interface GetNormailizeTextRulesRequest {
  siteId: number;
}

interface RemoveNormalizeTextRulesRequest {
  siteId: number;
  ruleIds: GridRowSelectionModel;
}

interface RemoveUnitConvertersRequest {
  siteId: number;
  unitConverterIds: number[] | GridRowSelectionModel;
}

interface UpdateParameterRequest {
  id: string;
  name: string;
  type: string;
  units: string;
  minValue: number;
  maxValue: number;
  norm: number;
  normDefinition: string;
  siteId: number;
  comment: string;
  isFavorite: boolean;
}

interface UpdatePointRequest {
  id: string;
  name: string;
  x: number;
  y: number;
  z: number;
  depth: number;
  maxDepth1: number;
  maxDepth2: number;
  maxDepth3: number;
  maxDepth4: number;
  hasSamplePoint: boolean;
  aquifer: string;
  type: string;
  siteId: string;
  comment: string;
  isFavorite: boolean;
}

interface UploadFileRequest {
  siteId: number;
  formData: FormData;
}

interface UploadFileResponse {
  duplicatedNamesInFile?: string[];
  duplicatedNamesInDatabase?: string[];
  fieldMissingPoints?: string[];
  fieldMissingParameters?: string[];
  closePointsDistance?: {
    pointAName: string;
    pointBName: string;
  }[];
}

interface ComputePointParameterStatRequest {
  siteId: number;
}

interface ComputePointParameterStatResult {}

//*****************************************  API  *****************************************//

export const extendedSiteDataAPI = api
  .enhanceEndpoints({
    addTagTypes: [
      'file-names',
      'site-unconfirmed-measure-sources',
      'site-points',
      'site-parameters',
      'site-units',
      'site-point-parameter-stats',
      'site-compute-stat-from',
      'site-normalizations',
      'site-unit-converters',
      'site-measures',
      'site-points-medias',
      'site-points-comments'
    ]
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getSiteUnconfirmedMeasureSourcesData: builder.query<
        GetSiteUnconfirmedMeasureSourcesDataResult,
        GetSiteUnconfirmedMeasureSourcesDataRequest
      >({
        query: (args) => ({
          url: `/sites/${args.siteId}/measure-imports/measure-sources/unconfirmed`,
          method: 'GET'
        }),
        providesTags: ['site-unconfirmed-measure-sources']
      }),

      importMeasureSources: builder.mutation<void, ImportMeasureSourcesRequest>({
        query: (args) => {
          return {
            url: `/sites/${args.siteId}/file-imports/raw-data`,
            method: 'POST',
            body: args.formData,
            formData: true
          };
        },
        invalidatesTags: ['site-unconfirmed-measure-sources', 'file-names']
      }),

      getFileNames: builder.query<GetImportedFileNamesResult, GetImportedFileNamesRequest>({
        query: (args) => ({
          url: `/sites/${args.siteId}/measures/imported-file-names`,
          method: 'GET'
        }),
        providesTags: ['file-names']
      }),

      getSitePoint: builder.query<GetSitePointResult, GetSitePointRequest>({
        query: (args) => {
          return {
            url: `/sites/${args.siteId}/points/${args.pointId}`,
            method: 'GET',
            params: {
              groupId: args.groupId && args.groupId !== '0' ? args.groupId : undefined
            }
          };
        },
        providesTags: ['site-points']
      }),

      getSitePoints: builder.query<GetSitePointsResult, GetSitePointsRequest>({
        query: (args) => {
          return {
            url: `/sites/${args.siteId}/points`,
            method: 'GET',
            params: {
              groupId: args.groupId && args.groupId !== '0' ? args.groupId : undefined
            }
          };
        },
        providesTags: ['site-points']
      }),

      getSiteParameter: builder.query<GetSiteParameterResult, GetSiteParameterRequest>({
        query: (args) => {
          return {
            url: `/sites/${args.siteId}/parameters/${args.parameterId}`,
            method: 'GET',
            params: {
              groupId: args.groupId && args.groupId !== '0' ? args.groupId : undefined
            }
          };
        },
        providesTags: ['site-parameters']
      }),

      getSiteParameters: builder.query<GetSiteParametersResult, GetSiteParametersRequest>({
        query: (args) => {
          return {
            url: `/sites/${args.siteId}/parameters`,
            method: 'GET',
            params: {
              groupId: args.groupId && args.groupId !== '0' ? args.groupId : undefined
            }
          };
        },
        providesTags: ['site-parameters']
      }),

      getSiteUnits: builder.query<GetSiteUnitsResult, GetSiteUnitsRequest>({
        query: (args) => ({
          url: `/sites/${args.siteId}/units`,
          method: 'GET'
        }),
        providesTags: ['site-units']
      }),

      getSitePointParameterStats: builder.query<GetSitePointParameterStatsResult, GetSitePointParameterStatsRequest>({
        query: (args) => {
          return {
            url: `/sites/${args.siteId}/point-parameter-stats`,
            method: 'GET',
            params: {
              groupId: args.groupId && args.groupId !== '0' ? args.groupId : undefined
            }
          };
        },
        providesTags: ['site-point-parameter-stats']
      }),

      getSiteUnitConverters: builder.query<GetSiteUnitConvertersResult, GetSiteUnitConvertersRequest>({
        query: (args) => ({
          url: `/sites/${args.siteId}/unit-converters`,
          method: 'GET'
        }),
        providesTags: ['site-unit-converters']
      }),

      addSitePoint: builder.mutation<AddSitePointResult, AddSitePointRequest>({
        query: (args) => ({
          url: `/sites/${args.siteId}/points`,
          method: 'POST',
          body: args
        }),
        invalidatesTags: ['site-points']
      }),

      deleteSitePoint: builder.mutation<DeleteSitePointResult, DeleteSitePointRequest>({
        query: (args) => ({
          url: `/sites/${args.siteId}/points`,
          method: 'DELETE',
          body: args
        }),
        invalidatesTags: ['site-points']
      }),

      uploadPointMedias: builder.mutation<void, UploadPointMediaRequest>({
        query: (args) => ({
          url: `/sites/${args.siteId}/points/${args.pointId}/medias`,
          method: 'POST',
          body: args.formData,
          formData: true
        }),
        invalidatesTags: ['site-points-medias']
      }),

      getPointMedias: builder.query<GetPointMediasResult, GetPointMediasRequest>({
        query: (args) => {
          return {
            url: `/sites/${args.siteId}/points/${args.pointId}/medias`,
            method: 'GET',
            params: {
              groupId: args.groupId && args.groupId !== '0' ? args.groupId : undefined
            }
          };
        },
        providesTags: ['site-points-medias']
      }),

      updatePointMediaComment: builder.mutation<void, UpdatePointMediaCommentRequest>({
        query: (args) => ({
          url: `/sites/${args.siteId}/points/${args.pointId}/medias/${args.mediaId}/comment`,
          method: 'PUT',
          body: {
            comment: args.comment
          }
        }),
        invalidatesTags: ['site-points-medias']
      }),

      addPointComment: builder.mutation<void, AddPointCommentRequest>({
        query: (args) => ({
          url: `/sites/${args.siteId}/points/${args.pointId}/comments`,
          method: 'POST',
          body: {
            author: args.author,
            comment: args.comment
          }
        }),
        invalidatesTags: ['site-points-comments']
      }),

      getPointComments: builder.query<GetPointCommentsResult, GetPointCommentsRequest>({
        query: (args) => ({
          url: `/sites/${args.siteId}/points/${args.pointId}/comments`,
          method: 'GET',
          params: {
            groupId: args.groupId && args.groupId !== '0' ? args.groupId : undefined
          }
        }),
        providesTags: ['site-points-comments']
      }),

      addSiteParameter: builder.mutation<AddSiteParameteResult, AddSiteParameterRequest>({
        query: (args) => ({
          url: `/sites/${args.siteId}/parameters`,
          method: 'POST',
          body: args
        }),
        invalidatesTags: ['site-parameters']
      }),

      deleteSiteParameter: builder.mutation<DeleteSiteParameterResult, DeleteSiteParameterRequest>({
        query: (args) => ({
          url: `/sites/${args.siteId}/parameters`,
          method: 'DELETE',
          body: args
        }),
        invalidatesTags: ['site-parameters']
      }),

      addSiteUnit: builder.mutation<AddSiteUnitResult, AddSiteUnitRequest>({
        query: (args) => ({
          url: `/sites/${args.siteId}/units`,
          method: 'POST',
          body: {
            name: args.name
          }
        }),
        invalidatesTags: ['site-units']
      }),

      addPointRule: builder.mutation<AddRuleResult, AddRuleRequest>({
        query: (args) => ({
          url: `/sites/${args.siteId}/normalize-text-rules/point`,
          method: 'POST',
          body: {
            oldValue: args.oldValue,
            newValue: args.newValue
          }
        }),
        invalidatesTags: ['site-unconfirmed-measure-sources', 'site-normalizations']
      }),

      updatePointRule: builder.mutation<UpdateRuleResult, UpdateRuleRequest>({
        query: (args) => ({
          url: `/sites/${args.siteId}/normalize-text-rules/point`,
          method: 'PUT',
          body: {
            normalizationId: args.normalizationId,
            newValue: args.newValue
          }
        }),
        invalidatesTags: ['site-unconfirmed-measure-sources', 'site-normalizations']
      }),

      addParameterRule: builder.mutation<AddRuleResult, AddRuleRequest>({
        query: (args) => ({
          url: `/sites/${args.siteId}/normalize-text-rules/parameter`,
          method: 'POST',
          body: {
            oldValue: args.oldValue,
            newValue: args.newValue
          }
        }),
        invalidatesTags: ['site-unconfirmed-measure-sources', 'site-normalizations']
      }),

      updateParameterRule: builder.mutation<UpdateRuleResult, UpdateRuleRequest>({
        query: (args) => ({
          url: `/sites/${args.siteId}/normalize-text-rules/parameter`,
          method: 'PUT',
          body: {
            normalizationId: args.normalizationId,
            newValue: args.newValue
          }
        }),
        invalidatesTags: ['site-unconfirmed-measure-sources', 'site-normalizations']
      }),

      addUnitRule: builder.mutation<AddRuleResult, AddRuleRequest>({
        query: (args) => ({
          url: `/sites/${args.siteId}/normalize-text-rules/unit`,
          method: 'POST',
          body: {
            oldValue: args.oldValue,
            newValue: args.newValue
          }
        }),
        invalidatesTags: ['site-unconfirmed-measure-sources', 'site-normalizations']
      }),

      updateUnitRule: builder.mutation<UpdateRuleResult, UpdateRuleRequest>({
        query: (args) => ({
          url: `/sites/${args.siteId}/normalize-text-rules/unit`,
          method: 'PUT',
          body: {
            normalizationId: args.normalizationId,
            newValue: args.newValue
          }
        }),
        invalidatesTags: ['site-unconfirmed-measure-sources', 'site-normalizations']
      }),

      addUnitConverter: builder.mutation<AddUnitConverterResult, AddUnitConverterRequest>({
        query: (args) => ({
          url: `/sites/${args.siteId}/unit-converters`,
          method: 'POST',
          body: {
            sourceUnit: args.sourceUnit,
            targetUnit: args.targetUnit,
            factor: args.factor
          }
        }),
        invalidatesTags: ['site-unit-converters', 'site-units']
      }),

      upDatePointName: builder.mutation<void, UpdatePointNameRequest>({
        query: (args) => ({
          url: `/sites/${args.siteId}/measure-imports/measure-sources/${args.measureSourceId}/points`,
          method: 'PUT',
          body: {
            pointId: args.pointId
          }
        })
      }),

      upDateParameterName: builder.mutation<void, UpdateParameterNameRequest>({
        query: (args) => ({
          url: `/sites/${args.siteId}/measure-imports/measure-sources/${args.measureSourceId}/parameters`,
          method: 'PUT',
          body: {
            parameterId: args.parameterId
          }
        })
      }),

      upDateUnitName: builder.mutation<void, UpdateUnitNameRequest>({
        query: (args) => ({
          url: `/sites/${args.siteId}/measure-imports/measure-sources/${args.measureSourceId}/units`,
          method: 'PUT',
          body: {
            unitId: args.unitId
          }
        })
      }),

      normalize: builder.mutation<void, NormalizeRequest>({
        query: (args) => ({
          url: `/sites/${args.siteId}/normalize-text-rules/apply`,
          method: 'POST',
          body: {
            normalizeParameters: args.normalizeParameters || false,
            normalizePoints: args.normalizePoints || false,
            normalizeUnits: args.normalizeUnits || false
          }
        }),
        invalidatesTags: ['site-unconfirmed-measure-sources', 'site-unit-converters']
      }),

      getNormailizeTextRules: builder.query<GetNormailizeTextRulesResult, GetNormailizeTextRulesRequest>({
        query: (args) => ({
          url: `/sites/${args.siteId}/normalize-text-rules`,
          method: 'GET'
        }),
        providesTags: ['site-normalizations']
      }),

      removeNormalizeTextRules: builder.mutation<void, RemoveNormalizeTextRulesRequest>({
        query: (args) => ({
          url: `/sites/${args.siteId}/normalize-text-rules`,
          method: 'DELETE',
          body: {
            normalizeTextRuleIds: args.ruleIds
          }
        }),
        invalidatesTags: ['site-normalizations']
      }),

      removeUnitConverters: builder.mutation<void, RemoveUnitConvertersRequest>({
        query: (args) => ({
          url: `/sites/${args.siteId}/unit-converters`,
          method: 'DELETE',
          body: {
            unitConverterIds: args.unitConverterIds
          }
        }),
        invalidatesTags: ['site-unit-converters']
      }),

      upDateMeasureSourceStatus: builder.mutation<void, UpdateMeasureSourceStatusRequest>({
        query: (args) => ({
          url: `/sites/${args.siteId}/measure-imports/measure-sources/status`,
          method: 'PUT',
          body: {
            measureSourceIds: args.measureSourceIds,
            status: args.status,
            verificationStatusChangedBy: args.verificationStatusChangedBy
          }
        })
      }),

      confirmMeasureImport: builder.mutation<AddConfirmedMeasuresResult, AddConfirmedMeasuresRequest>({
        query: (args) => ({
          url: `/sites/${args.siteId}/measures/confirm-import`,
          method: 'POST',
          body: {
            measureSourceIds: args.measureSourceIds,
            confirmBy: args.confirmBy
          }
        }),
        invalidatesTags: [
          'site-unconfirmed-measure-sources',
          'site-measures',
          'site-point-parameter-stats',
          'site-measures',
          'site-points',
          'site-parameters'
        ]
      }),

      removeMeasureSources: builder.mutation<void, RemoveMeasureSourcesRequest>({
        query: (args) => ({
          url: `/sites/${args.siteId}/measure-imports/measure-sources`,
          method: 'DELETE',
          body: {
            measureSourceIds: args.measureSourceIds
          }
        }),
        invalidatesTags: ['site-unconfirmed-measure-sources', 'site-point-parameter-stats']
      }),

      removeMeasures: builder.mutation<void, RemoveMeasuresRequest>({
        query: (args) => ({
          url: `/sites/${args.siteId}/measures`,
          method: 'DELETE',
          body: {
            measuresToDelete: args.measuresToDelete,
            isHardDelete: args.isHardDelete
          }
        }),
        invalidatesTags: ['site-measures', 'site-point-parameter-stats', 'site-points', 'site-parameters']
      }),

      restoreMeasures: builder.mutation<void, RestoreMeasuresRequest>({
        query: (args) => ({
          url: `/sites/${args.siteId}/measures/restore`,
          method: 'PUT',
          body: {
            measureIdsToRestore: args.measureIdsToRestore
          }
        }),
        invalidatesTags: ['site-measures', 'site-point-parameter-stats', 'site-points', 'site-parameters']
      }),

      updateParameter: builder.mutation<void, UpdateParameterRequest>({
        query: (args) => ({
          url: `/sites/${args.siteId}/parameters`,
          method: 'PUT',
          body: args
        }),
        invalidatesTags: ['site-parameters']
      }),

      uploadParametersFile: builder.mutation<UploadFileResponse, UploadFileRequest>({
        query: (args) => ({
          url: `/sites/${args.siteId}/parameters/import`,
          method: 'POST',
          body: args.formData
        }),
        invalidatesTags: ['site-parameters']
      }),

      updatePoint: builder.mutation<void, UpdatePointRequest>({
        query: (args) => ({
          url: `/sites/${args.siteId}/points`,
          method: 'PUT',
          body: args
        }),
        invalidatesTags: ['site-points']
      }),

      uploadPointsFile: builder.mutation<UploadFileResponse, UploadFileRequest>({
        query: (args) => ({
          url: `/sites/${args.siteId}/points/import`,
          method: 'POST',
          body: args.formData
        }),
        invalidatesTags: ['site-points']
      }),
      computePointParameterStat: builder.mutation<ComputePointParameterStatResult, ComputePointParameterStatRequest>({
        query: (args) => ({
          url: `/sites/${args.siteId}/point-parameter-stats`,
          method: 'PUT'
        }),
        invalidatesTags: ['site-point-parameter-stats', 'site-points', 'site-parameters']
      })
    })
  });

//****************************************  SLICES  ****************************************//

export const slice = createSlice({
  name: 'site-data',
  initialState,
  reducers: {},
  extraReducers: (builder) => {}
});

//****************************************  THUNKS  ****************************************//

//****************************************  EXPORT  ****************************************//

export const { reducer } = slice;

export const {
  useGetSiteUnconfirmedMeasureSourcesDataQuery,
  useImportMeasureSourcesMutation,
  useGetFileNamesQuery,
  useGetSitePointQuery,
  useGetSitePointsQuery,
  useGetSiteParameterQuery,
  useGetSiteParametersQuery,
  useGetSiteUnitsQuery,
  useGetSitePointParameterStatsQuery,
  useGetSiteUnitConvertersQuery,
  useAddSitePointMutation,
  useDeleteSitePointMutation,
  useUploadPointMediasMutation,
  useGetPointMediasQuery,
  useUpdatePointMediaCommentMutation,
  useAddPointCommentMutation,
  useGetPointCommentsQuery,
  useAddSiteParameterMutation,
  useDeleteSiteParameterMutation,
  useAddSiteUnitMutation,
  useAddPointRuleMutation,
  useUpdatePointRuleMutation,
  useAddParameterRuleMutation,
  useUpdateParameterRuleMutation,
  useAddUnitRuleMutation,
  useUpdateUnitRuleMutation,
  useAddUnitConverterMutation,
  useUpDatePointNameMutation,
  useUpDateParameterNameMutation,
  useUpDateUnitNameMutation,
  useUpDateMeasureSourceStatusMutation,
  useNormalizeMutation,
  useGetNormailizeTextRulesQuery,
  useRemoveNormalizeTextRulesMutation,
  useConfirmMeasureImportMutation,
  useRemoveMeasureSourcesMutation,
  useRestoreMeasuresMutation,
  useRemoveMeasuresMutation,
  useRemoveUnitConvertersMutation,
  useUpdateParameterMutation,
  useUpdatePointMutation,
  useUploadParametersFileMutation,
  useUploadPointsFileMutation,
  useComputePointParameterStatMutation
} = extendedSiteDataAPI;
