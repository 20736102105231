import { FC, useMemo } from 'react';
import { Container, Divider, Grid, IconButton, Tab, Tabs, Typography } from '@mui/material';
import { t, Trans } from '@lingui/macro';
import PointParameterStats from './point-parameter-stats';
import About from './about';
import { Close } from '@mui/icons-material';
import DeletedMeasures from './deleted-measures';
import { useOrganizationRole } from 'src/hooks/use-role';
import { OrganizationRoles } from 'src/models/memberRoles';
import KrigisSettings from './krigis-settings';
import useTabs from 'src/hooks/useTabs';
import useOrganizationId from '../../../../hooks/useOrganizationId';
import OrganizationMembersManagement from './organization-members';

type SettingsProps = {
  onClose: () => void;
};

const Settings: FC<SettingsProps> = ({ onClose }) => {
  const organizationId = useOrganizationId();
  const userTabs: { key: string; label: string; component: JSX.Element }[] = useMemo(
    () => [
      { key: 'about', label: t`About`, component: <About /> },
      { key: 'kigis-settings', label: t`Krigis settings`, component: <KrigisSettings /> }
    ],
    []
  );

  const adminTabs: { key: string; label: string; component: JSX.Element }[] = useMemo(
    () => [
      {
        key: 'manage-organization-members',
        label: t`Manage Organization Members`,
        component: <OrganizationMembersManagement organizationId={organizationId} />
      },
      { key: 'point-parameter-stats', label: t`Point parameter stats`, component: <PointParameterStats /> },
      { key: 'deleted-measures', label: t`Deleted measurements`, component: <DeletedMeasures onClose={onClose} /> },
      ...userTabs
    ],
    [onClose, organizationId, userTabs]
  );

  const role = useOrganizationRole();

  const tabs = useMemo(() => {
    switch (role) {
      case OrganizationRoles.Administrator:
        return adminTabs;
      case OrganizationRoles.Member:
        return userTabs;
      default:
        return [];
    }
  }, [adminTabs, role, userTabs]);

  const { tab, handleTabChange } = useTabs({ defaultTab: tabs[0], tabs: tabs, searchParamKey: 'settings-tab' });

  return (
    <Container
      sx={{
        backgroundColor: 'background.default',
        py: 2,
        px: 4,
        width: '50vw',
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Grid container sx={{ mb: 1, alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography color="textPrimary" variant="h5">
          <Trans>General settings</Trans>
        </Typography>
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </Grid>
      <Divider sx={{ mb: 1 }} />

      <Tabs
        scrollButtons="auto"
        allowScrollButtonsMobile
        variant="scrollable"
        value={tab.key}
        onChange={handleTabChange}
      >
        {tabs.map((tab) => (
          <Tab key={tab.key} value={tab.key} label={tab.label} />
        ))}
      </Tabs>
      {tab.component}
    </Container>
  );
};

export default Settings;
