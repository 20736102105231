import React from 'react';
import {
  DataGridPro,
  GridCellParams,
  GridColDef,
  GridEditMode,
  GridInputRowSelectionModel,
  GridPaginationModel,
  GridRowsProp,
  UncapitalizedGridProSlotsComponent,
  useGridApiRef
} from '@mui/x-data-grid-pro';
import useSiteId from '../hooks/useSiteId';
import { GridRowSelectionModel, GridSortModel } from '@mui/x-data-grid';
import { GridProSlotProps } from '@mui/x-data-grid-pro/models/gridProSlotProps';
import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';
import { SxProps, Theme } from '@mui/material';

interface PersistentGridProps<R = any> {
  checkboxSelection?: boolean;
  columns: GridColDef[];
  disableRowSelectionOnClick?: boolean;
  editMode?: GridEditMode;
  initialState?: GridInitialStatePro;
  isCellEditable?: (params: GridCellParams<any, any, any>) => boolean;
  loading?: boolean;
  onCellDoubleClick?: (params: GridColDef, event: React.MouseEvent) => void;
  onColumnHeaderDoubleClick?: (params: GridColDef, event: React.MouseEvent) => void;
  onPaginationModelChange?: (model: GridPaginationModel) => void;
  onRowSelectionModelChange?: (newModel: GridRowSelectionModel) => void;
  onSortModelChange?: (model: GridSortModel) => void;
  pageSizeOptions?: number[];
  pagination?: boolean;
  paginationMode?: 'server' | 'client';
  paginationModel?: GridPaginationModel;
  persistentKey: string;
  processRowUpdate?: Promise<R> | R;
  rowCount?: number;
  rows: GridRowsProp;
  rowSelectionModel?: GridInputRowSelectionModel;
  rowHeight?: number;
  slots?: Partial<UncapitalizedGridProSlotsComponent>;
  slotProps?: GridProSlotProps;
  sortModel?: GridSortModel;
  style?: React.CSSProperties;
  sx?: SxProps<Theme>;
}

function saveStateSnapshotToLocalStorage(
  stateSnapshot: GridInitialStatePro,
  localStorageKey: string,
  gridName: string
) {
  const stringifiedState = localStorage.getItem(localStorageKey);
  const newState = stringifiedState ? JSON.parse(stringifiedState) : {};
  newState[gridName] = stateSnapshot;
  localStorage.setItem(localStorageKey, JSON.stringify(newState));
}

function getStateSnapshotFromLocalStorage(localStorageKey: string, gridName: string) {
  const stringifiedState = localStorage.getItem(localStorageKey);
  return new Promise((resolve) => {
    if (!stringifiedState) {
      return resolve({});
    }
    const stateSnapshot = JSON.parse(stringifiedState);
    return resolve(stateSnapshot[gridName]);
  });
}

const PersistentGrid = ({ persistentKey, initialState, ...props }: PersistentGridProps) => {
  const siteId = useSiteId();
  const apiRef = useGridApiRef();
  const [cachedInitialState, setCachedInitialState] = React.useState<GridInitialStatePro | null>(null);
  const localStorageKey = `site-${siteId}-grids`;

  React.useEffect(() => {
    const getSnapshot = async () => {
      const snapshotFromLocalStorage = await getStateSnapshotFromLocalStorage(localStorageKey, persistentKey);
      setCachedInitialState(snapshotFromLocalStorage);
    };
    getSnapshot();
  }, [localStorageKey, persistentKey, siteId]);

  const saveSnapshot = React.useCallback(() => {
    if (!apiRef.current) {
      return;
    }
    const stateSnapshot = apiRef.current.exportState();
    saveStateSnapshotToLocalStorage(stateSnapshot, localStorageKey, persistentKey);
  }, [apiRef, localStorageKey, persistentKey]);

  const handleStateChange = React.useCallback(() => {
    saveSnapshot();
  }, [saveSnapshot]);

  if (cachedInitialState === null) {
    return null;
  }

  return (
    <DataGridPro
      {...props}
      key={`${localStorageKey}-${persistentKey}`}
      apiRef={apiRef}
      onStateChange={handleStateChange}
      initialState={{
        ...initialState,
        ...cachedInitialState
      }}
    />
  );
};

export default PersistentGrid;
