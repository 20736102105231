import { lazy, Suspense } from 'react';
import AuthGuard from './components/guards/auth-guard';
import GuestGuard from './components/guards/guest-guard';
import OrganizationGuard from './components/guards/organization-guard';
import RouteSiteGuard from './pages/member/guards/route-site-guard';
import AdministrationLayout from './layouts/administration-layout';
import ResponsiveLayout from './layouts/responsive-layout';
import EmptyLayout from './layouts/empty-layout';
import LoadingScreen from './screens/LoadingScreen';
import SignIn from './pages/auth/signin';
import SignUp from './pages/auth/signup';
import DefaultSiteRedirection from './pages/member/default-site-redirection';
import { OrganizationRoles, UserRoles } from './models/memberRoles';
import SiteConfigProvider from './pages/member/providers/site-config-provider';

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// Admin Pages
const AdminOrganizations = Loadable(lazy(() => import('./pages/admin/organizations')));
const AdminOrganizationDetails = Loadable(lazy(() => import('./pages/admin/organizations/details')));
const OrganizationMemberSiteMembers = Loadable(lazy(() => import('./pages/member/organizations/members')));
const AdminSite = Loadable(lazy(() => import('./pages/admin/sites/details')));

// General Pages
const OrganizationMemberSiteData = Loadable(lazy(() => import('./pages/member/sites/data')));
const OrganizationMemberSiteGroups = Loadable(lazy(() => import('./pages/member/sites/groups')));
const OrganizationMemberSiteSettings = Loadable(lazy(() => import('./pages/member/sites/settings')));
const OrganizationMemberSiteShare = Loadable(lazy(() => import('./pages/member/sites/share')));
const OrganizationMemberSiteImportData = Loadable(lazy(() => import('./pages/member/sites/import-data')));
const OrganizationMemberSiteGroupDetails = Loadable(lazy(() => import('./pages/member/groups/edit')));
const OrganizationMemberSiteTable = Loadable(lazy(() => import('./pages/member/table')));
const OrganizationMemberSiteMap = Loadable(lazy(() => import('./pages/member/map')));

// Error Pages
const AuthorizationRequired = Loadable(lazy(() => import('./pages/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/ServerError')));
const NoAccess = Loadable(lazy(() => import('./pages/NoAccess')));

const routes = [
  {
    path: '/',
    element: (
      <AuthGuard role={UserRoles.User}>
        <RouteSiteGuard>
          <SiteConfigProvider>
            <ResponsiveLayout />
          </SiteConfigProvider>
        </RouteSiteGuard>
      </AuthGuard>
    ),
    children: [
      {
        path: '/',
        element: <DefaultSiteRedirection />
      },

      {
        path: ':organizationId/:siteId/:groupId/table',
        element: <OrganizationMemberSiteTable />
      },
      {
        path: ':organizationId/:siteId/:groupId/map',
        element: <OrganizationMemberSiteMap />
      },
      {
        path: ':organizationId/:siteId/:groupId/data',
        element: <OrganizationMemberSiteData />
      },

      {
        path: '*',
        element: <OrganizationGuard role={OrganizationRoles.Administrator} />,
        children: [
          {
            path: ':organizationId/members',
            element: <OrganizationMemberSiteMembers />
          },
          {
            path: ':organizationId/:siteId/share',
            element: <OrganizationMemberSiteShare />
          },
          {
            path: ':organizationId/:siteId/import',
            element: <OrganizationMemberSiteImportData />
          },
          {
            path: ':organizationId/:siteId/groups',
            element: <OrganizationMemberSiteGroups />
          },
          {
            path: ':organizationId/:siteId/settings',
            element: <OrganizationMemberSiteSettings />
          },
          {
            path: ':organizationId/:siteId/group/:selectedGroupId',
            element: <OrganizationMemberSiteGroupDetails />
          }
        ]
      }
    ]
  },
  {
    path: '/administration',
    element: (
      <AuthGuard role={UserRoles.SuperAdministrator}>
        <AdministrationLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <AdminOrganizations />
      },
      {
        path: 'organizations',
        children: [
          {
            path: ':organizationId',
            element: <AdminOrganizationDetails />
          },
          {
            path: ':organizationId/:siteId',
            element: <AdminSite />
          },
          {
            path: ':organizationId/members',
            element: <AdminOrganizationDetails />
          }
        ]
      }
    ]
  },

  {
    path: '/auth',
    element: <EmptyLayout />,
    children: [
      {
        path: 'signin',
        element: (
          <GuestGuard>
            <SignIn />
          </GuestGuard>
        )
      },
      {
        path: 'signup',
        element: (
          <GuestGuard>
            <SignUp />
          </GuestGuard>
        )
      }
    ]
  },
  {
    path: '*',
    children: [
      {
        path: '401',
        element: <AuthorizationRequired />
      },
      {
        path: '404',
        element: <NotFound />
      },
      {
        path: '403',
        element: <NoAccess />
      },
      {
        path: '500',
        element: <ServerError />
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  }
];

export default routes;
