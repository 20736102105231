import { t } from '@lingui/macro';
import { Button, Grid } from '@mui/material';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteMeasuresDialog from './delete-measures-dialog';
import RestoreMeasuresDialog from './restore-measures-dialog';

interface DeletedMeasuresActionsProps {
  selectedRowIds: number[];
  setSelectedRowIds: Dispatch<SetStateAction<number[]>>;
}

const DeletedMeasuresActions: FC<DeletedMeasuresActionsProps> = ({ selectedRowIds, setSelectedRowIds }) => {
  const [openConfirmRestoreDialog, setOpenConfirmRestoreDialog] = useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);

  return (
    <Grid gap={1} container direction="row" justifyContent="end" marginY={1}>
      <Button
        disabled={selectedRowIds.length === 0}
        variant="contained"
        onClick={() => {
          setOpenConfirmRestoreDialog(true);
        }}
        startIcon={<DeleteIcon />}
      >
        {t`Restore` + ` (${selectedRowIds.length})`}
      </Button>
      <Button
        disabled={selectedRowIds.length === 0}
        variant="contained"
        onClick={() => {
          setOpenConfirmDeleteDialog(true);
        }}
        color={'error'}
        startIcon={<DeleteIcon />}
      >
        {t`Delete permanently` + ` (${selectedRowIds.length})`}
      </Button>
      <RestoreMeasuresDialog
        openConfirmRestoreDialog={openConfirmRestoreDialog}
        setOpenConfirmRestoreDialog={setOpenConfirmRestoreDialog}
        selectedRowIds={selectedRowIds}
        setSelectedRowIds={setSelectedRowIds}
      />
      <DeleteMeasuresDialog
        openConfirmDeleteDialog={openConfirmDeleteDialog}
        setOpenConfirmDeleteDialog={setOpenConfirmDeleteDialog}
        selectedRowIds={selectedRowIds}
        setSelectedRowIds={setSelectedRowIds}
      />
    </Grid>
  );
};

export default DeletedMeasuresActions;
