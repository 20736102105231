import type { FC } from 'react';

// Externals
import { Container, Link, Typography } from '@mui/material';
import { Trans } from '@lingui/macro';

const AuthorizedRequired: FC = () => {
  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
      }}
    >
      <Typography
        variant="h1"
        color="primary"
        sx={{
          fontSize: '4rem',
          marginBottom: '2rem'
        }}
      >
        <Trans>401 : Authentication required</Trans>
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{
          fontSize: '1.5rem'
        }}
      >
        <Trans>You don't have the permission to access the requested resource.</Trans>
      </Typography>
      <Link
        href="/"
        sx={{
          fontSize: '1.5rem',
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <Trans>Back to home page</Trans>
      </Link>
    </Container>
  );
};

export default AuthorizedRequired;
