import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import api from './api';
import { Parameter, Point } from 'src/types/site-data.model';
import moment from 'moment';

export interface Measure {
  id: number;
  siteId: number;
  measureSourceId?: number;
  date: string;
  sourcePointName: string;
  pointName: string;
  pointId: string;
  x: number;
  y: number;
  sourceParameterName: string;
  parameterName: string;
  parameterId: string;
  parameterUnit: string;
  sourceUnitName: string;
  unitName: string;
  sourceAmount: string;
  amount: number;
  detectionLimit?: number;
  underDetectionLimit: boolean;
  comments: string;
  analysisId: string;
  sampleId: string;
  laboratoryName: string;
  measureImportFileName: string;
  sourceImportedAt: Date;
  sourceConfirmedAt?: Date;
  sourceConfirmedBy: string;
  isDeleted: boolean;
  deleteBy?: string;
  deletedAt?: Date;
  deleteComment?: string;
}

export interface MinMaxResult {
  minAmount: number;
  maxAmount: number;
}

export type RangeType = 'week' | 'month' | 'quarter' | 'semester' | 'year' | 'custom';

export interface DateRange {
  startDate: string;
  endDate: string;
  range: RangeType;
}

/***** API ******/

interface GetPointsRequest {
  siteId: number;
  groupId?: string;
}

interface GetPointsResult {
  points: Point[];
}

interface GetParametersRequest {
  siteId: number;
  groupId?: string;
}

interface GetParametersResult {
  parameters: Parameter[];
}

interface GetMeasuresRequest {
  siteId: number;
  groupId?: string;
  parameterId?: string;
  pointId?: string;
  startDate?: string;
  endDate?: string;
  onlyDeleted?: boolean;
  minimalInfo?: boolean;
  pageIndex?: number;
  pageSize?: number;
}

interface GetMeasuresResult {
  measures: Measure[];
  pageSize: number;
  pageIndex: number;
  totalPages: number;
  totalCount: number;
}

interface GetMinMaxMeasuresRequest {
  siteId: number;
  parameterId: string;
  groupId?: string;
  startDate?: string;
  endDate?: string;
}

interface GetMinMaxMeasuresResult {
  minMax: MinMaxResult;
}

export const extendedDashboardAPI = api
  .enhanceEndpoints({ addTagTypes: ['site-points', 'site-parameters', 'site-layers', 'site-measures'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getPoints: builder.query<GetPointsResult, GetPointsRequest>({
        query: (args) => {
          return {
            url: `/sites/${args.siteId}/points`,
            method: 'GET',
            params: {
              groupId: args.groupId && args.groupId !== '0' ? args.groupId : undefined
            }
          };
        },
        providesTags: ['site-points']
      }),
      getParameters: builder.query<GetParametersResult, GetParametersRequest>({
        query: (args) => {
          return {
            url: `/sites/${args.siteId}/parameters`,
            method: 'GET',
            params: {
              groupId: args.groupId && args.groupId !== '0' ? args.groupId : undefined
            }
          };
        },
        providesTags: ['site-parameters']
      }),
      getMeasures: builder.query<GetMeasuresResult, GetMeasuresRequest>({
        query: (args) => {
          return {
            url: `/sites/${args.siteId}/measures`,
            method: 'GET',
            params: {
              groupId: args.groupId && args.groupId !== '0' ? args.groupId : undefined,
              parameterId: args.parameterId && args.parameterId !== 'none' ? args.parameterId : undefined,
              pointId: args.pointId && args.pointId !== 'none' ? args.pointId : undefined,
              afterDate: args.startDate || undefined,
              beforeDate: args.endDate || undefined,
              onlyDeleted: args.onlyDeleted,
              minimalInfo: args.minimalInfo,
              pageIndex: args.pageIndex,
              pageSize: args.pageSize ?? 1000000
            }
          };
        },
        providesTags: ['site-measures']
      }),
      getMinMaxMeasures: builder.query<GetMinMaxMeasuresResult, GetMinMaxMeasuresRequest>({
        query: (args) => {
          return {
            url: `/sites/${args.siteId}/parameters/${args.parameterId}/measures/min-max`,
            method: 'GET',
            params: {
              groupId: args.groupId && args.groupId !== '0' ? args.groupId : undefined,
              startDate: args.startDate,
              endDate: args.endDate
            }
          };
        }
      })
    })
  });

/****** REDUCERS *******/

export interface DashboardState {
  selectedPoints: string[];
  selectedPoint: string;
  selectedParameters: string[];
  focusedPointData: { point: string; isGraphOpen: boolean };
  dateRange: DateRange;
  zoomLevel: number;
  mapPosition: { x: number; y: number };
}

const initialState: DashboardState = {
  selectedPoints: [],
  selectedPoint: '',
  selectedParameters: [],
  dateRange: {
    startDate: moment().startOf('year').toISOString(),
    endDate: moment().endOf('year').toISOString(),
    range: 'year'
  },
  focusedPointData: { point: '', isGraphOpen: false },
  zoomLevel: 0,
  mapPosition: { x: 0, y: 0 }
};

const slice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    initializeDashboard: (state, action: PayloadAction<DashboardState>) => {
      state.selectedPoints = action.payload.selectedPoints || initialState.selectedPoints;
      state.selectedPoint = action.payload.selectedPoint || initialState.selectedPoint;
      state.selectedParameters = action.payload.selectedParameters || initialState.selectedParameters;
      state.focusedPointData = action.payload.focusedPointData || initialState.focusedPointData;
      state.zoomLevel = action.payload.zoomLevel || initialState.zoomLevel;
      state.mapPosition = action.payload.mapPosition || initialState.mapPosition;
      state.dateRange.endDate = action.payload.dateRange.endDate || state.dateRange.endDate;
      state.dateRange.startDate = action.payload.dateRange.startDate || state.dateRange.startDate;
      state.dateRange.range = action.payload.dateRange.range || state.dateRange.range;
    },
    setFocusedPointData: (
      state,
      action: PayloadAction<{
        point: string;
        isGraphOpen: boolean;
      }>
    ) => {
      state.focusedPointData = action.payload;
    },
    setSelectedRange: (state, action: PayloadAction<DateRange>) => {
      state.dateRange = action.payload;
    },
    addPrimaryPoint: (state, action: PayloadAction<string>) => {
      state.selectedPoint = action.payload;
    },
    addPointToSelection: (state, action: PayloadAction<string>) => {
      // if point is already selected, return
      if (state.selectedPoints.includes(action.payload)) return;

      //if already 2 parameters, only keep the first one
      if (state.selectedParameters.length >= 2) {
        state.selectedParameters = [state.selectedParameters[0]];
      }

      // add point to selectedPoints
      state.selectedPoints.push(action.payload);
    },
    addParameterToSelection: (state, action: PayloadAction<string>) => {
      // if parameter is already selected, return
      if (state.selectedParameters.includes(action.payload)) return;

      // if number of selected parameter >=1, keep the first point in selectedPoints
      if (state.selectedParameters.length >= 1 && state.selectedPoints.length >= 1) state.selectedPoints = [];

      // if 2 parameters selected, remove the second one
      if (state.selectedParameters.length >= 2) state.selectedParameters.pop();

      // add parameter to selectedParameters
      state.selectedParameters.push(action.payload);
    },
    removeParameterFromSelection: (state, action: PayloadAction<string>) => {
      state.selectedParameters = state.selectedParameters.filter((p) => p !== action.payload);
    },
    removePrimaryPoint: (state) => {
      state.selectedPoint = null;
    },
    removePointFromSelection: (state, action: PayloadAction<string>) => {
      state.selectedPoints = state.selectedPoints.filter((p) => p !== action.payload);
    },
    resetSelection: (state) => {
      state.selectedPoints = [];
      state.selectedParameters = [];
    },
    resetPointsSelection: (state) => {
      state.selectedPoints = [];
    },
    resetParametersSelection: (state) => {
      state.selectedParameters = [];
    }
  }
});

export const setSelectedRange =
  (dateRange: { startDate: string; endDate: string; range: RangeType }): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      dispatch(slice.actions.setSelectedRange(dateRange));
    } catch (error) {
      console.log(error);
    }
  };

export const { reducer } = slice;

export const {
  initializeDashboard,
  addPointToSelection,
  addPrimaryPoint,
  removePointFromSelection,
  removePrimaryPoint,
  addParameterToSelection,
  removeParameterFromSelection,
  resetPointsSelection,
  resetParametersSelection,
  setFocusedPointData,
  resetSelection
} = slice.actions;

export const { useGetPointsQuery, useGetParametersQuery, useGetMeasuresQuery, useGetMinMaxMeasuresQuery } =
  extendedDashboardAPI;
