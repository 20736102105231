import useUserLocalStorage from 'src/hooks/use-user-local-storage';
export const useUpdateLastPath = () => {
  //Attributes
  const { setItem } = useUserLocalStorage();

  //Methods
  const updateLastPath = (organizationId, newSiteId, lastVisitedGroupId) => {
    // Global last path
    setItem(`organization`, organizationId.toString());
    setItem(`site`, newSiteId.toString());
    setItem(`group`, lastVisitedGroupId);

    // Per organization/site last path
    setItem(`org-${organizationId}-last-site`, newSiteId.toString());
    setItem(`site-${newSiteId}-last-group`, lastVisitedGroupId);
  };

  //Return
  return { updateLastPath };
};
