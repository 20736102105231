import { plural, t, Trans } from '@lingui/macro';
import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Dispatch, FC, SetStateAction } from 'react';
import toast from 'react-hot-toast';
import useSiteId from 'src/hooks/useSiteId';
import { useRemoveMeasuresMutation } from 'src/slices/importdata';

interface DeleteMeasuresDialogProps {
  openConfirmDeleteDialog: boolean;
  setOpenConfirmDeleteDialog: Dispatch<SetStateAction<boolean>>;
  selectedRowIds: number[];
  setSelectedRowIds: Dispatch<SetStateAction<number[]>>;
}

const DeleteMeasuresDialog: FC<DeleteMeasuresDialogProps> = ({
  openConfirmDeleteDialog,
  setOpenConfirmDeleteDialog,
  selectedRowIds,
  setSelectedRowIds
}) => {
  const siteId = useSiteId();

  const [removeMeasures, { isLoading: isRemoveMeasuresLoading }] = useRemoveMeasuresMutation();

  return (
    <Dialog
      open={openConfirmDeleteDialog}
      onClose={() => setOpenConfirmDeleteDialog(false)}
      PaperProps={{ style: { width: '100%' } }}
    >
      <DialogTitle>
        <Trans>Confirm deletion</Trans>
      </DialogTitle>
      <DialogContent>
        {plural(selectedRowIds.length, {
          one: 'Are you sure you want to delete this measurement permanently?',
          other: 'Are you sure you want to delete these # measurements permanently?'
        })}
      </DialogContent>
      <DialogActions sx={{ flexDirection: 'row', gap: 1, paddingX: 3, paddingBottom: 3 }}>
        <Button
          variant="outlined"
          onClick={() => {
            setOpenConfirmDeleteDialog(false);
          }}
        >
          <Trans>Cancel</Trans>
        </Button>
        <LoadingButton
          loading={isRemoveMeasuresLoading}
          color="error"
          variant="contained"
          onClick={async () => {
            try {
              const measuresToDelete = selectedRowIds.map((row) => {
                return { measureId: row, comment: '' };
              });

              await removeMeasures({
                siteId: siteId,
                measuresToDelete: measuresToDelete,
                isHardDelete: true
              });
              setSelectedRowIds([]);
            } catch (e) {
              console.log(e);
              toast.error(t`An error occured while deleting measurements.`);
            }

            setOpenConfirmDeleteDialog(false);
          }}
        >
          <Trans>Delete permanently</Trans>
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteMeasuresDialog;
