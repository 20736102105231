import { toast } from 'react-hot-toast';

export const request = async ({
  api,
  authorized,
  body,
  method,
  contentType,
  formData
}: {
  api: string;
  authorized: boolean;
  body?: any;
  method: 'DELETE' | 'GET' | 'POST' | 'PUT' | 'OPTIONS';
  contentType?: string;
  formData?: FormData;
}): Promise<any> => {
  return new Promise((resolve: (json: any) => void, reject: (error: any) => void) => {
    const token = window.localStorage.getItem('token');
    if (!token && authorized) reject({ errors: ['Missing access token'] });

    let headers = {
      accept: 'application/json',
      'access-control-allow-methods': 'DELETE, GET, POST, PUT, OPTIONS',
      ...(authorized && { authorization: `Bearer ${token}` })
    };

    if (!formData) {
      headers['content-type'] = contentType || 'application/json';
    }

    let options = {
      name: 'json',
      method,
      headers,
      ...(body && {
        body: JSON.stringify(body)
      }),
      ...(formData && {
        body: formData
      })
    };

    // FETCH
    fetch(api, options)
      .then((response) => {
        if (response.status === 403) {
          toast.error('You are not authorized to perform this action');
          reject(new Error('You are not authorized to perform this action'));
        }
        if (response.status === 401) {
          window.localStorage.removeItem('token');
          window.location.reload();
        }
        if (response.ok) {
          response.text().then((text) => {
            resolve(text.length ? JSON.parse(text) : undefined);
          });
        } else {
          response.text().then((text) => {
            if (text.length) {
              try {
                const error = JSON.parse(text);
                reject(error);
              } catch (err) {
                reject(new Error('An unknown error occurred. Please try again later.'));
              }
            } else {
              reject(new Error());
            }
          });
        }
      })
      .catch((err) => {
        reject(new Error('It looks like our servers have a problem. Our developers are on it!'));
      });
  });
};
