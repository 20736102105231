import { createRoot } from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { I18nProvider } from '@lingui/react';
import { i18n } from '@lingui/core';
import { messages as messagesFr } from 'src/locales/fr/messages';
import { messages as messagesEn } from 'src/locales/en/messages';
import App from './App';
import './index.css';
import store from './store';
import { LicenseInfo } from '@mui/x-license';

i18n.load('en', messagesEn);
i18n.load('fr', messagesFr);

const localStorageLang = localStorage.getItem('lang') === 'fr' ? 'fr' : 'en';
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY);

i18n.activate(localStorageLang);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <CookiesProvider>
    <ReduxProvider store={store}>
      <BrowserRouter>
        <I18nProvider i18n={i18n}>
          <App />
        </I18nProvider>
      </BrowserRouter>
    </ReduxProvider>
  </CookiesProvider>
);
