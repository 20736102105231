import { Trans } from '@lingui/macro';
import { MenuItem, Select, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'src/store';
import { setLocalRegion } from 'src/slices/organization';
import useRegionOptions from 'src/hooks/useRegionOptions';

const KrigisSettings = () => {
  const REGION_OPTIONS = useRegionOptions();

  const dispatch = useDispatch();
  const localRegion = useSelector((state) => state.organization.localRegion);
  const userId = useSelector((state) => state.authentication.user.id);

  return (
    <Table>
      <TableBody>
        <TableRow
          sx={{
            '& > *:not(:last-child)': {
              borderRight: '1px solid rgba(0, 0, 0, 0.06)'
            }
          }}
        >
          <TableCell>
            <Typography variant="body1" fontWeight="bold">
              <Trans>Regional settings</Trans>
            </Typography>
            <Typography variant="body2" color="textSecondary">
              <Trans>Choose how dates and numbers are formatted for the entire organization</Trans>
            </Typography>
          </TableCell>
          <TableCell>
            <Select
              sx={{
                alignSelf: 'flex-start',
                minWidth: 150,
                '& .MuiSelect-outlined': {
                  paddingTop: '12.5px',
                  paddingBottom: '12.5px'
                },
                borderRadius: 0
              }}
              value={localRegion}
              onChange={(event) => {
                dispatch(setLocalRegion({ localRegion: event.target.value, userId }));
              }}
            >
              {REGION_OPTIONS.sort((o1, o2) => o1.label.localeCompare(o2.label)).map((selectOption) => (
                <MenuItem key={selectOption.value} value={selectOption.value}>
                  {selectOption.label}
                </MenuItem>
              ))}
            </Select>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default KrigisSettings;
