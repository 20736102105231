import * as React from 'react';

// Externals
import { LockOutlined } from '@mui/icons-material';
import { Avatar, Box, Checkbox, Container, FormControlLabel, Grid, Link, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';

// Slices
import { useLoginMutation } from 'src/slices/authentication';
import { Trans, t } from '@lingui/macro';
import { isMobile } from 'src/utils/location';

const Copyright = () => {
  return (
    <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 8, mb: 4 }}>
      {'Copyright © '}
      <Link color="inherit" href="https://www.krigis.tech/">
        Krigis
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
};

export default function SignIn() {
  const isMobileView = isMobile();

  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');

  const [login] = useLoginMutation();

  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    setSubmitting(true);

    event.preventDefault();

    const data = new FormData(event.currentTarget);

    try {
      await login({ email: data.get('email').toString(), password: data.get('password').toString() }).unwrap();
    } catch (err) {
      toast.error(t`Your username and password do not match.`);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: isMobileView ? 0 : 8,
          minHeight: isMobileView ? '100vh' : 'auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: isMobileView ? 'center' : 'flex-start',
          alignItems: 'center'
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlined />
        </Avatar>
        <Typography component="h1" variant="h5">
          <Trans>Sign in</Trans>
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label={t`Email Address`}
            name="email"
            autoComplete="email"
            autoFocus
            defaultValue={email}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label={t`Password`}
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <FormControlLabel control={<Checkbox value="remember" color="primary" />} label={t`Remember me`} />
          <LoadingButton
            loading={submitting}
            disabled={submitting}
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            <Trans>Sign In</Trans>
          </LoadingButton>
          <Grid
            container
            direction={isMobileView ? 'column' : undefined}
            alignItems={isMobileView ? 'center' : undefined}
            gap={isMobileView ? 2 : undefined}
          >
            <Grid item xs>
              <Link href="#" variant="body2">
                <Trans>Forgot password?</Trans>
              </Link>
            </Grid>
            <Grid item>
              <Link href="https://ngapio5kavz.typeform.com/to/S0TAAhSp" target="_blank" variant="body2">
                <Trans>Don't have an account? Contact us</Trans>
              </Link>
            </Grid>
          </Grid>
        </Box>
        <Copyright />
      </Box>
    </Container>
  );
}
